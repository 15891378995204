import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { AbstractControl, FormControl } from "@angular/forms";
import { DropdownHelperService } from "../../../../../core/helpers/dropdown-helper.service";
import { SelectOptionKeyboardEvent } from "../easy-select-recursive-option/easy-select-recursive-option.component";

@Component({
  selector: "app-easy-form-select",
  templateUrl: "./easy-form-select.component.html",
  styleUrls: ["./easy-form-select.component.scss", "../forms.styles.scss"],
})
export class EasyFormSelectComponent implements OnInit, OnChanges {
  @HostListener("document:click", ["$event"])
  onGlobalClick(event: Event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isOpen = false;
      this.shouldDisplayAbove = false;
      this.canAppear = false;
    }
  }

  @ViewChild("easySelect") public ornSelectEl?: ElementRef;
  @ViewChild("toggle") public toggleEl?: ElementRef;
  @ViewChild("optionsList") public optionsListEl?: ElementRef;

  /**
   * Label of the select
   */
  @Input() public label: string = "";

  /**
   * HTML name/id of the select
   * @required
   */
  @Input() public selectName: string = "";

  /**
   * Background color of the select
   */
  @Input() public background: "grey" | "white" = "grey";

  /**
   * Size of the select
   */
  @Input() public size: "small" | "medium" = "medium";

  /**
   * Form control of the select
   * @required
   */
  @Input() public control!: FormControl;

  /**
   * If true, a search bar will be displayed in the options
   */
  @Input() public search: boolean = true;

  /**
   * Errors map of the select
   * ex: [{'required': 'This field is required'}]
   */
  @Input() public errors: Map<string, string> = new Map<string, string>();

  /**
   * Options of the select
   */
  @Input() public options: EasySelectOption[] = [];

  @Input() public readonly: boolean = false;

  @Input() public sort: boolean = true;

  @Input() public clearable: boolean = false;

  @Input() public clickableGroups: boolean = false;

  @Input() public alwaysAbove: boolean = false;

  /**
   * This event occurs everytime the value changes
   */
  @Output() public valueChange: EventEmitter<string> =
    new EventEmitter<string>();

  public selectedOption?: EasySelectOption;
  public isOpen: boolean = false;
  public shouldDisplayAbove: boolean = false;
  public canAppear: boolean = false;
  public isRequired: boolean = false;

  public searchControl: FormControl = new FormControl("");
  public displayedOptions: EasySelectOption[] = [];

  constructor(
    private elementRef: ElementRef,
    private dropdownHelper: DropdownHelperService
  ) {}

  ngOnInit(): void {
    this.displayedOptions = this.options.slice();

    if (this.control) {
      const id: string = this.control.value?.toString();
      this.selectedOption = this.recursiveFind(this.options, id);
    }

    const validator = this.control.validator?.call(this, {} as AbstractControl);
    if (validator && validator.required) {
      this.isRequired = true;
    }

    this.control.valueChanges.subscribe(() => {
      const id: string = this.control.value;
      this.selectedOption = this.recursiveFind(this.options, id);
    });

    if (this.sort) {
      this.sortGroupOptions();
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.options) {
      this.displayedOptions = this.options.slice();
      if (this.sort) {
        this.sortGroupOptions();
      }
      const id: string = this.control.value;
      this.selectedOption = this.recursiveFind(this.options, id);
    }
  }

  private sortGroupOptions() {
    /* TODO */
  }

  // ------------------------------------------------  EVENTS HANDLERS  -------------------------------------------------------------------- //

  public showOptions(bool: boolean, event?: any): void {
    if (this.control.enabled && !this.readonly) {
      this.isOpen = bool;

      if (this.isOpen) {
        if (this.selectedOption) {
          setTimeout(() => {
            this.detectOverflow();
            this.dropdownHelper.focusSelectedOption();
          }, 0);
        } else {
          setTimeout(() => {
            this.detectOverflow();
            this.dropdownHelper.focusFirstOption();
          }, 0);
        }
      }
    } else if (event) {
      event.preventDefault();
    }
  }

  // ------------------------------------------------  EVENTS HANDLERS  -------------------------------------------------------------------- //

  public handleOptionClick(option: EasySelectOption): void {
    if (!option.disabled) {
      this.control.setValue(option.value);
      this.valueChange.emit(option.value);
      this.selectedOption = option;
      this.isOpen = false;
      this.shouldDisplayAbove = false;
      this.canAppear = false;
      this.searchControl.setValue("");
      this.handleSearchChange("");

      this.toggleEl?.nativeElement.focus();
    }
  }

  public handleLabelClick(event: any): void {
    event.preventDefault();
    if (this.control.enabled) {
      this.showOptions(!this.isOpen);
      setTimeout(() => {
        this.toggleEl?.nativeElement.focus();
      }, 0);
    }
  }

  public handleButtonKeydown(event: KeyboardEvent): void {
    this.dropdownHelper.handleButtonKeydown(event);
  }

  public handleButtonKeyup(event: KeyboardEvent): void {
    this.dropdownHelper.handleButtonKeyup(
      event,
      this.readonly,
      this,
      "optionsListEl",
      this.selectedOption,
      () => {
        this.showOptions(!this.isOpen);
      },
      () => {
        this.isOpen = true;
      },
      (bool: boolean) => {
        this.shouldDisplayAbove = bool;
        this.canAppear = true;
      }
    );
  }

  public handleOptionKeydown(event: SelectOptionKeyboardEvent): void {
    this.dropdownHelper.handleOptionKeydown(
      event.keyboardEvent,
      this.selectName + "option" + event.optionId,
      () => {
        this.isOpen = false;
      }
    );
  }

  public handleOptionKeyup(event: SelectOptionKeyboardEvent): void {
    this.dropdownHelper.handleOptionKeyup(
      event.keyboardEvent,
      () =>
        this.handleOptionClick(
          this.options.find(
            (opt) => opt.id == event.optionId
          ) as EasySelectOption
        ),
      () => {
        this.isOpen = false;
        this.shouldDisplayAbove = false;
        this.canAppear = false;
        this.toggleEl?.nativeElement.focus();
      }
    );
  }

  public handleClearClick(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    this.control.setValue(undefined);
    this.valueChange.emit(undefined);
    this.selectedOption = undefined;
    this.isOpen = false;
    this.shouldDisplayAbove = false;
    this.canAppear = false;

    this.toggleEl?.nativeElement.focus();
  }

  public handleSearchChange(value: string): void {
    if (value) {
      this.displayedOptions = this.options.filter((o) =>
        o.label.toString().toLowerCase().includes(value.toLowerCase())
      );
    } else {
      this.displayedOptions = this.options.slice();
    }
  }

  // ------------------------------------------------  MISC  -------------------------------------------------------------------- //

  private detectOverflow(): void {
    const listRect = this.optionsListEl?.nativeElement.getBoundingClientRect();
    if (listRect) {
      const listHeight = listRect.height;
      const listOffsetY = listRect.y;
      this.shouldDisplayAbove = listHeight + listOffsetY > window.innerHeight;
      this.canAppear = true;
    }
  }

  private recursiveFind(mainList: EasySelectOption[], id: string): any {
    let element: any = mainList.find(
      (opt: EasySelectOption) => opt.id.toString() == id
    );
    if (element) {
      return element;
    }
    for (const subList of mainList) {
      if (subList.children) {
        element = this.recursiveFind(subList.children, id);
        if (element) {
          return element;
        }
      }
    }
    return element;
  }
}

export class EasySelectOption {
  id: string | any;
  label: string | number | boolean;
  value: any;
  icon?: string;
  iconEnd?: string;
  disabled?: boolean;
  children?: EasySelectOption[];
  color?: "info" | "success" | "warning" | "danger";

  constructor(label: string) {
    this.id = label;
    this.label = label;
    this.value = label;
  }
}
