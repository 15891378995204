import {Component, Input} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: "app-easy-form-errors",
  templateUrl: "./easy-form-errors.component.html",
  styleUrls: ["./easy-form-errors.component.scss"],
})
export class EasyFormErrorsComponent {
  @Input()
  public control: FormControl = new FormControl();

  @Input()
  public errors: Map<string, string> = new Map<string, string>();
}
