import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-header-item",
  templateUrl: "./header-item.component.html",
  styleUrls: ["./header-item.component.scss"],
})
export class HeaderItemComponent implements OnInit {
  @Input()
  public icon?: string = "";

  @Input()
  public label: string = "";

  @Input()
  public url: string = "";

  constructor() {}

  ngOnInit(): void {}
}
