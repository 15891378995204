<button class="easy-button" [type]="type" [class.disabled]="disabled"
        [class.default]="color === 'default'"
        [class.primary]="color === 'primary'"
        [class.info]="color === 'info'"
        [class.warning]="color === 'warning'"
        [class.danger]="color === 'danger'"
        [class.success]="color === 'success'"
        [class.raised]="buttonStyle === 'raised'" [class.flat]="buttonStyle ===
    'flat'"
        [class.borderless]="buttonStyle === 'borderless'" [class.active]="active"
        [class.small]="size === 'small'" [class.medium]="size === 'medium'"
        [title]="ariaLabel || label"
        [id]="buttonId+'Button'"
        [attr.aria-labelledby]="buttonId"
        [class.fullWidth]="fullWidth"
        [routerLink]="routerLink"
        (click)="handleClick($event)">
    <div class="overlay"></div>
    <div *ngIf="icon" class="icon-container" [class.marginRight]="label">
        <span [ngClass]="icon"></span>
    </div>
    <span *ngIf="label" class="label" aria-hidden="true">{{ label }}</span>
    <span [id]="buttonId" class="offscreen" aria-hidden="true">
        {{ ariaLabel || label }}
    </span>
    <div *ngIf="endIcon" class="icon icon-container" [class.marginLeft]="label">
        <span [ngClass]="endIcon"></span>
    </div>
</button>