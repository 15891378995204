import {Component, ElementRef, OnInit, Renderer2, OnDestroy} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {combineLatest, Observable, Subject} from 'rxjs';
import {takeUntil, tap} from 'rxjs/operators';
import {ConnectedUser, UserLoggedService} from '../../../core/services/userLogged.service';
import {Language} from '../../../core/models/language.model';
import {LanguagesService} from '../../../core/services/languages.service';

@Component({
  selector: "app-header-language",
  templateUrl: "./header-language.component.html",
  styleUrls: ["./header-language.component.scss"],
})
export class HeaderLanguageComponent implements OnInit, OnDestroy {
  private languages$: Observable<Language[]> = this.langService.languages$;

  public languages: Language[] = [];

  public dateFormat: any[] = [
    { name: "English-US", tag: "en-US", field: "(M/DD/YYYY)" },
    { name: "European", tag: "eu-EU", field: "(DD/MM/YYYY)" },
    { name: "Japanese", tag: "ja-JP", field: "(YYYY/M/DD)" },
  ];

  private connectedUser$: Observable<ConnectedUser> =
    this.userLoggedService.connectedUser$;

  public currentLanguage?: Language;

  public open: boolean = false;

  private listener?: Function;

  private onDestroy$: Subject<void> = new Subject();

  constructor(
    private eRef: ElementRef,
    private renderer: Renderer2,
    public translate: TranslateService,
    private langService: LanguagesService,
    public userLoggedService: UserLoggedService
  ) {}

  ngOnInit(): void {
    combineLatest([this.connectedUser$, this.languages$])
      .pipe(
        takeUntil(this.onDestroy$),
        tap(([user, languages]) => {
          this.languages = languages;
          this.currentLanguage =
            languages.find((l) => l.key == user.language) ?? languages[0];
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public handleLanguageClick(lang: string): void {
    if (this.currentLanguage.key != lang) {
      this.userLoggedService
        .updateLanguage(lang)
        .pipe(
          tap((l) => {
            this.translate.use(lang);
          })
        )
        .subscribe();
    }
  }

  public toggleDropdown(): void {
    this.open = !this.open;
    if (this.open) {
      this.listener = this.renderer.listen("document", "click", (event) => {
        if (this.open && !this.eRef.nativeElement.contains(event.target)) {
          this.open = false;
        }
        if (!this.open) {
          this.listener();
        }
      });
    }
  }
}
