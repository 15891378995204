import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {KeycloakService} from 'keycloak-angular';
import {BehaviorSubject, Observable} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {MessageHandlerService} from './messageHandler.service';
import {environment} from '../../../environments/environment';

const UPDATE_LNG = environment.baseUrl + '/users/customer/updateLanguage';
const UPDATE_DATE = environment.baseUrl + '/users/customer/updateDateFormat';

@Injectable({
  providedIn: 'root',
})
export class UserLoggedService {
  private _connectedUser$: BehaviorSubject<ConnectedUser> =
    new BehaviorSubject<ConnectedUser>(undefined);

  constructor(
    private keycloak: KeycloakService,
    private messageService: MessageHandlerService,
    private http: HttpClient
  ) {
  }

  // --------------------------------------------- SETTING CURRENT USER ---------------------------------------------------------- //

  public reloadCurrentUser() {
    const currentInstance = this.keycloak.getKeycloakInstance();

    const connectedUser: ConnectedUser = {
      customerId: currentInstance.tokenParsed['customerId'],
      customerType: currentInstance.tokenParsed['customerType'],
      dateFormat: this.getCurrentDateFormat(currentInstance),
      factory: currentInstance.tokenParsed['factory'],
      id: currentInstance.tokenParsed['userId'],
      language: this.getCurrentLanguage(currentInstance),
      username: currentInstance.tokenParsed['preferred_username'],
      email: currentInstance.tokenParsed['email'],
      eulaStatus: currentInstance.tokenParsed['eulaStatus'],
      darkTheme: currentInstance.tokenParsed['darkTheme'],
      rackLocationFeature: currentInstance.tokenParsed['rackLocationFeature'],
      roles: currentInstance.tokenParsed['realm_access'].roles,
    };

    this._connectedUser$.next(connectedUser);
    return connectedUser;
  }

  private getCurrentLanguage(currentInstance: any): string {
    let language: string = currentInstance.tokenParsed['language'];

    if (!currentInstance.authenticated || !language) {
      language = 'en-GB';
    }
    return language;
  }

  private getCurrentDateFormat(currentInstance: any): string {
    let dateFormat = currentInstance.tokenParsed['dateFormat'];

    if (!currentInstance.authenticated || !dateFormat) {
      dateFormat = 'ja-JP';
    } else if (dateFormat == 'de-DE') {
      dateFormat = 'eu-EU';
    }

    return dateFormat;
  }

  // --------------------------------------------- HTTP FUNCTIONS ---------------------------------------------------------- //

  firstUpdateLanguage(language: string): Observable<any> {
    return this.http
      .put<any>(UPDATE_LNG + '/' + language, undefined)
      .pipe(catchError(this.messageService.handleError<any>('update langage')));
  }

  updateLanguage(language: string): Observable<any> {
    return this.http.put<any>(UPDATE_LNG + '/' + language, undefined).pipe(
      tap(async () => {
        await this.keycloak.updateToken(-1);
        this.reloadCurrentUser();
      }),
      catchError(this.messageService.handleError<any>('update langage'))
    );
  }

  getDateFormat(): string {
    if (!this.keycloak.getKeycloakInstance().authenticated) return 'ja-JP';
    if (this.keycloak.getKeycloakInstance().tokenParsed['dateFormat'] == '')
      return 'ja-JP';
    if (
      this.keycloak.getKeycloakInstance().tokenParsed['dateFormat'] ===
      undefined
    )
      return 'ja-JP';
    if (
      this.keycloak.getKeycloakInstance().tokenParsed['dateFormat'] === 'de-DE'
    )
      return 'eu-EU';
    return this.keycloak.getKeycloakInstance().tokenParsed['dateFormat'];
  }

  updateDateFormat(format: string): Observable<any> {
    if (format == 'eu-EU') {
      format = 'de-DE';
    }
    return this.http.put<any>(UPDATE_DATE + '/' + format, undefined).pipe(
      tap(async () => {
        await this.keycloak.updateToken(-1);
        this.reloadCurrentUser();

        this.messageService.log(
          'Validation',
          `The date format has been updated`,
          1,
          true
        );
      }),
      catchError(this.messageService.handleError<any>('update date format'))
    );
  }

  public updateDarkTheme(darkTheme: boolean): Observable<any> {
    return this.http
      .put<any>(
        environment.baseUrl + '/users/customer/updateTheme/' + darkTheme,
        undefined
      )
      .pipe(
        tap(async () => {
          await this.keycloak.updateToken(-1);
          this.reloadCurrentUser();
        }),
        catchError(this.messageService.handleError<any>('update langage'))
      );
  }

  // --------------------------------------------- ACCESSORS ---------------------------------------------------------- //

  public get connectedUser$(): Observable<ConnectedUser> {
    return this._connectedUser$.asObservable();
  }
}

export interface ConnectedUser {
  id: number;
  username: string;
  customerId: string;
  customerType: string;
  language: string;
  factory: string;
  dateFormat: string;
  email: string;
  eulaStatus: boolean;
  darkTheme: boolean;
  rackLocationFeature: boolean;
  roles: string[];
}
