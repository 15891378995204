import { Component, ElementRef, Input, OnInit, Renderer2 } from "@angular/core";

@Component({
  selector: "app-header-account",
  templateUrl: "./header-account.component.html",
  styleUrls: ["./header-account.component.scss"],
})
export class HeaderAccountComponent implements OnInit {
  @Input()
  public username: string = "";

  public open: boolean = false;

  private listener: Function;

  public logoutModalOpen: boolean = false;

  constructor(private eRef: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {}

  public handleLogout() {
    this.logoutModalOpen = true;
  }

  public toggleDropdown(): void {
    this.open = !this.open;
    if (this.open) {
      this.listener = this.renderer.listen("document", "click", (event) => {
        if (this.open && !this.eRef.nativeElement.contains(event.target)) {
          this.open = false;
        }
        if (!this.open) {
          this.listener();
        }
      });
    }
  }
}
