<app-easy-modal
  [modalTitle]="'COMMON.COMMON.DELETE' | translate"
  [overlayClose]="true"
  [open]="open"
  (close)="closeModal()"
>
  <div class="confirmation-body">
    {{ 'MODELS.BATCHES.CONFIRMATIONDELETE' | translate }}
  </div>
  <div class="confirmation-footer" footer>
    <app-easy-button
      [label]="'COMMON.COMMON.CANCEL' | translate"
      (click)="closeModal()"
    ></app-easy-button>
    <app-easy-button
      color="danger"
      [label]="'COMMON.COMMON.DELETE' | translate"
      icon="fas fa-trash-alt"
      (click)="confirmDelete()"
    ></app-easy-button>
  </div>
</app-easy-modal>
