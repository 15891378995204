import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {Language} from '../models/language.model';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: "root",
})
export class LanguagesService {
  private serviceUrl: string = environment.babelApiUrl + "/languages";

  private _languages$: BehaviorSubject<Language[]> = new BehaviorSubject<
    Language[]
  >([]);

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}

  public getAllLanguages(): Observable<Language[]> {
    return this.http.get<Language[]>(this.serviceUrl + "/apps/et-adm").pipe(
      map((langs: Language[]) => {
        return langs.map((l) => {
          l.flagIcon = this.sanitizer.bypassSecurityTrustHtml(l.flagSvg);
          return l;
        });
      }),
      tap((langs: Language[]) => {
        this._languages$.next(langs);
      }),
      catchError(() => {
        console.error("Loading languages failed");
        return of([]);
      })
    );
  }

  public get languages$(): Observable<Language[]> {
    return this._languages$.asObservable();
  }
}
