export enum EventMessageType {
  BATCH_COUNTER = "batch-counter",
  DAMAGED_TYRE_COUNTER_EVENT = "damaged-tyre-counter-event",
  BATCH_UPDATED_EVENT = "batch-updated",
  UPDATED_TYRE_EVENT = "tyre-updated",
  DATA_STREAM = "data-stream",
  DATA_STREAM_NOTICE = "data-stream-notice",
  DATA_STREAM_PROGRESS = "data-stream-progress",
  NEW_NOTIFICATION = "new-notification-event",
  SSO_LOGOUT_EVENT = "sso-logout-event",
}
