import {Component, ElementRef, Input, OnInit, Renderer2, ViewChild,} from '@angular/core';

@Component({
  selector: "app-big-button",
  templateUrl: "./big-button.component.html",
  styleUrls: ["./big-button.component.scss"],
})
export class BigButtonComponent implements OnInit {
  @ViewChild("toggleButton") toggleButton: ElementRef;
  @ViewChild("menu") menu: ElementRef;

  @Input()
  public icon: string = "";

  @Input() public label: string = "";

  @Input() public ariaLabel: string = "";

  @Input() public active: boolean = false;

  @Input() public color: "danger" | "warning" | "success" | "info" | "default" =
    "default";

  @Input() public options: BigButtonOption[] = [];

  public open: boolean = false;

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {
    this.renderer.listen("window", "click", (e: Event) => {
      /**
       * Only run when toggleButton is not clicked
       * If we don't check this, all clicks (even on the toggle button) gets into this
       * section which in the result we might never see the menu open!
       * And the menu itself is checked here, and it's where we check just outside of
       * the menu and button the condition abbove must close the menu
       */
      if (
        e.target !== this.toggleButton.nativeElement &&
        e.target !== this.menu?.nativeElement &&
        !this.toggleButton?.nativeElement.contains(e.target)
      ) {
        this.open = false;
      }
    });
  }

  public handleButtonClick() {
    if (this.options.length > 0) {
      this.open = !this.open;
    }
  }
}

export interface BigButtonOption {
  iconStart?: string;
  iconEnd?: string;
  label: string;
  callback: () => any;
}
