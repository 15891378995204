import {Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild, OnChanges,} from '@angular/core';
import {AbstractControl, FormControl} from '@angular/forms';
import {DropdownHelperService} from '../../../../../core/helpers/dropdown-helper.service';

@Component({
  selector: "app-easy-form-input",
  templateUrl: "./easy-form-input.component.html",
  styleUrls: ["./easy-form-input.component.scss", "../forms.styles.scss"],
})
export class EasyFormInputComponent implements OnInit, OnChanges {
  @ViewChild("input") public inputEl?: ElementRef;
  @ViewChild("optionsList") public optionsListEl?: ElementRef;
  /**
   * Label of the input
   */
  @Input() public label: string = "";

  /**
   * Placeholder of the input
   */
  @Input() public placeholder: string = "";

  /**
   * Icon of the input
   */
  @Input() public icon: string = "";

  /**
   * REQUIRED ! HTML name/id of the input
   * @required
   */
  @Input() public inputName: string = "";

  /**
   * Background color of the input
   */
  @Input() public background: "grey" | "white" = "grey";

  /**
   * REQUIRED ! Form control fo the input
   * @required
   */
  @Input() public control: FormControl = new FormControl();

  /**
   * Errors map of the input
   * ex: [{'required': 'This field is required'}]
   */
  @Input() public errors: Map<string, string> = new Map<string, string>();

  /**
   * Type of the input
   */
  @Input() public type: string = "text";

  /**
   * Size of the input
   */
  @Input() public size: "small" | "medium" = "medium";

  /**
   * Max length of the input
   */
  @Input() public maxLength: number = 100;

  /**
   * Max value of the input
   */
  @Input() public max?: number = undefined;

  /**
   * Autocomplete options of the input
   */
  @Input() public options: string[] = [];

  /**
   * Number max of options displayed at the same time
   * Value 0 for no maximum.
   */
  @Input() public maxOptions: number = 5;

  /**
   * If true, the input is read only
   */
  @Input() public readonly: boolean = false;

  /**
   * This event occurs everytime the value changes
   */
  @Output() public valueChange: EventEmitter<string> =
    new EventEmitter<string>();

  public isOpen: boolean = false;
  public shouldDisplayAbove: boolean = false;
  public canAppear: boolean = false;

  public displayedOptions: string[] = [];

  public isRequired: boolean = false;

  constructor(private dropdownHelper: DropdownHelperService) {}

  public ngOnInit() {
    const validator = this.control.validator?.call(this, {} as AbstractControl);
    if (validator && validator.required) {
      this.isRequired = true;
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.max) {
      if (this.max != undefined && this.type === "number") {
        if (this.control.value > this.max) {
          this.control.setValue(this.max);
        }
      }
      this.valueChange.emit(this.control.value);
    }
  }

  // ------------------------------------------------  EVENTS HANDLERS -> INPUT -------------------------------------------------------------------- //

  public handleInputKeyUp(event: KeyboardEvent): void {
    if (this.options.length > 0) {
      const key = event.key;
      switch (key) {
        case "ArrowUp": {
          if (this.isOpen) {
            this.dropdownHelper.focusLastOption();
          }
          event.preventDefault();
          break;
        }
        case "ArrowDown": {
          if (this.isOpen) {
            this.dropdownHelper.focusFirstOption();
          }
          event.preventDefault();
          break;
        }
        case "Escape": {
          event.preventDefault();
          event.stopPropagation();
          this.isOpen = false;
          this.canAppear = false;
          break;
        }
        default: {
          if (!this.readonly) {
            const value = this.control.value;

            if (value.length > 0) {
              this.displayedOptions = this.options
                .filter((opt) =>
                  opt.toLowerCase().startsWith(value.toLowerCase())
                )
                .slice(0, this.maxOptions > 0 ? this.maxOptions : undefined);
              this.canDisplayOptions();

              if (this.max != undefined && this.type === "number") {
                if (value > this.max) {
                  this.control.setValue(this.max);
                }
              }
              this.valueChange.emit(this.control.value);
            } else {
              this.displayedOptions = [];
              this.canDisplayOptions();
            }
          }

          break;
        }
      }
    } else {
      if (!this.readonly) {
        if (this.max != undefined && this.type === "number") {
          if (this.control.value > this.max) {
            this.control.setValue(this.max);
          }
        }
        this.valueChange.emit(this.control.value);
      }
    }
  }

  public handleInputKeyDown(event: KeyboardEvent): void {
    const key = event.key;
    switch (key) {
      case "ArrowUp": {
        event.preventDefault();
        break;
      }
      case "ArrowDown": {
        event.preventDefault();
        break;
      }
      case "Tab": {
        if (event.shiftKey) {
          this.isOpen = false;
        }
        break;
      }
    }
  }

  // ------------------------------------------------  EVENTS HANDLERS -> OPTIONS -------------------------------------------------------------------- //

  public handleOptionKeydown(event: KeyboardEvent, index: number): void {
    this.dropdownHelper.handleOptionKeydown(event, "option_" + index, () => {
      this.isOpen = false;
    });
  }

  public handleOptionKeyup(event: KeyboardEvent, index: number): void {
    this.dropdownHelper.handleOptionKeyup(
      event,
      () => {
        this.handleOptionClick(this.displayedOptions[index]);
      },
      () => {
        this.isOpen = false;
        this.inputEl?.nativeElement.focus();
      }
    );
  }

  public handleOptionClick(option: any): void {
    this.control.setValue(option);
    this.valueChange.emit(this.control.value);
    this.inputEl?.nativeElement.focus();
    this.isOpen = false;
    this.shouldDisplayAbove = false;
    this.canAppear = false;
  }

  public canDisplayOptions() {
    if (this.options.length > 0) {
      this.showOptions(this.displayedOptions.length > 0);
    }
  }

  // -------------------------------------------------  OPTIONS FUNCTIONS  -------------------------------------------------------------------- //

  // private focusFirstOption(): void {
  //   const firstOption = document.querySelector("li:first-child") as HTMLElement;
  //   firstOption.focus();
  // }

  // private focusLastOption(): void {
  //   const lastOption = document.querySelector("li:last-child") as HTMLElement;
  //   lastOption.focus();
  // }

  public showOptions(bool: boolean, event?: any): void {
    if (this.control.enabled && !this.readonly) {
      this.isOpen = bool;

      if (this.isOpen) {
        setTimeout(() => {
          this.detectOverflow();
        }, 0);
      }
    } else if (event) {
      event.preventDefault();
    }
  }

  // -------------------------------------------------  MISC  -------------------------------------------------------------------- //

  private detectOverflow(): void {
    const listRect = this.optionsListEl?.nativeElement.getBoundingClientRect();
    if (listRect) {
      const listHeight = listRect.height;
      const listOffsetY = listRect.y;
      this.shouldDisplayAbove = listHeight + listOffsetY > window.innerHeight;
      this.canAppear = true;
    }
  }
}
