
<div class="big-button">

    <div #toggleButton class="big-button-container" [class.active]="active"
        [class.open]="open"
        [ngClass]="[color]"
        [title]="ariaLabel ?? label" [attr.aria-label]="ariaLabel"
        (click)="handleButtonClick()">
        <span *ngIf="icon" [ngClass]="icon"></span>
        <span *ngIf="label">{{ label | translate }}</span>

    </div>

    <div #menu *ngIf="open" class="options">
        <div *ngFor="let option of options" class="option"
            (click)="option.callback();this.open=false;">
            <span *ngIf="option.iconStart" [ngClass]="option.iconStart"></span>
            <span>{{ option.label | translate }}</span>
            <span *ngIf="option.iconEnd" [ngClass]="option.iconEnd"></span>
        </div>
    </div>
</div>
