<div id="headerAccount" class="header-account" (click)="toggleDropdown()">
    <div class="toggle">
        <span class="fas fa-chevron-down icon"></span>
        <div class="user-icon">
            <span class="fas fa-user"></span>
        </div>
        <span class="username" [title]="username">{{ username }}</span>
    </div>
    <div *ngIf="open" class="account-dropdown">
        <div id="accountSettingsButton" class="account-item"
            routerLink="/me/settings">
            <span class="fas fa-cog icon"></span>
            <span class="title">{{ 'GLOBAL.GLOBAL.SETTINGS' | translate }}</span>
        </div>
        <div class="account-item" (click)="handleLogout()">
            <span class="fas fa-power-off icon"></span>
            <span class="title">{{ 'GLOBAL.GLOBAL.LOG_OUT' | translate }}</span>
        </div>
    </div>
</div>

<app-easy-modal [modalTitle]="'GLOBAL.GLOBAL.LOG_OUT' | translate"
    [overlayClose]="true"
    [open]="logoutModalOpen" [fitWidth]="true" (close)="logoutModalOpen=
    false;">
    <div class="logout-body">
        {{ "GLOBAL.GLOBAL.LOG_OUT_CONFIRMATION" | translate }}
    </div>
    <div class="logout-footer" footer>
        <app-easy-button [label]="'COMMON.COMMON.CANCEL' | translate"
            (click)="logoutModalOpen= false;">
        </app-easy-button>
        <app-easy-button color="danger"
            [label]="'GLOBAL.GLOBAL.LOG_OUT' | translate" icon="fas
            fa-power-off"
            routerLink="/logout">
        </app-easy-button>
    </div>
</app-easy-modal>