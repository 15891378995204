<div class="header-notifications" (click)="toggleDropdown()">
    <div class="toggle">
        <div style="position:relative;">
            <span class="fas fa-bell icon"></span>
            <div *ngIf="notReadCount > 0"
                class="counter-badge">
                <span>{{ notReadCount }}</span>
            </div>
        </div>

    </div>

    <div *ngIf="open" class="notifications-dropdown"
        (click)="$event.preventDefault();$event.stopPropagation();">
        <div class="tabs">
            <div class="tab-button" [class.active]="activeTab === 'all'"
                (click)="handleTabClick('all')">
                {{ 'COMMON.COMMON.ALL' | translate }}
            </div>
            <div class="tab-button" [class.active]="activeTab === 'unseen'"
                (click)="handleTabClick('unseen')">
                {{ 'MODELS.NOTIFICATIONS.NOT_SEEN' | translate }}
            </div>
        </div>
        <div class="notifications">
            <div *ngFor="let notification of displayedNotifications"
                class="notification"
                (click)="handleNotificationClick(notification)">
                <div class="content">
                    <div class="notification-title"
                        [class.not-read]="!notification.read">{{
                        notification.title }}</div>
                    <div class="notification-text"
                        [innerHTML]="notification.text"></div>
                    <div class="notification-date"
                        [class.not-read]="!notification.read">
                        {{ notification.creationDate }}
                    </div>
                </div>
                <div class="seen-toggle" [class.visible]="!notification.read">
                    <span class="fas fa-circle"
                        (click)="$event.stopPropagation();toggleNotificationSeen(notification)"></span>
                </div>
            </div>
            <div *ngIf="displayedNotifications.length == 0"
                class="no-notification">
                {{ "MODELS.NOTIFICATIONS.NO_NOTIFICATIONS" | translate }}
            </div>
        </div>
    </div>
</div>