<div class="easy-form-input input-container" [class.withErrors]="control.touched
    && control?.errors"
    [class.disabled]="control.disabled" [class.readonly]="readonly">
    <label *ngIf="label" [for]="inputName">
        <span>{{ label }}</span>
        <span *ngIf="isRequired" class="required-star">&nbsp;*</span>
    </label>
    <div style="position: relative;">

        <span *ngIf="icon" class="icon" [ngClass]="icon"></span>
        <input #input [class.bg-white]="background === 'white'" [type]="type"
            [placeholder]="placeholder" [name]="inputName"
            [id]="inputName" [formControl]="control" [maxLength]="maxLength"
            [class.open]="isOpen" [max]="max"
            (keyup)="handleInputKeyUp($event)"
            (keydown)="handleInputKeyDown($event)" (focus)="canDisplayOptions()"
            autocomplete="off" role="textbox"
            [attr.aria-haspopup]="options.length> 0 ? 'listbox' : false"
        [attr.aria-controls]="options.length > 0 ? inputName+ '_list' : null"
        [attr.aria-autocomplete]="options.length > 0 ? 'list' : 'none'"
        [readonly]="readonly" />
        <!-- ----------------------------------------  OPTIONS LIST  ------------------------------------------------- -->
        <ul *ngIf="isOpen" [id]="inputName+ '_list'" #optionsList
            class="select-options-list shadow"
            [class.displayAbove]="shouldDisplayAbove"
            [class.is-visible]="canAppear" tabindex="-1" role="listbox"
            [attr.aria-labelledby]="inputName + '_label'">

            <!-- ----------------------------------------  OPTION  ------------------------------------------------- -->
            <li *ngFor="let option of displayedOptions;let i= index"
                [id]="'option_' + i" class="select-option py-2 px-3"
                (click)="handleOptionClick(option)"
                (keydown)="handleOptionKeydown($event, i)"
                (keyup)="handleOptionKeyup($event, i)" role="option"
                tabindex="0"
                [attr.aria-selected]="control.value == option">

                <div class="option-label ">{{ option }}</div>

            </li>
        </ul>
    </div>
    <app-easy-form-errors *ngIf="errors?.size> 0 && control.touched &&
        control?.errors"
        [errors]="errors" [control]="control">
    </app-easy-form-errors>
</div>