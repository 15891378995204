import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild,} from '@angular/core';
import {AbstractControl, FormControl} from '@angular/forms';

@Component({
  selector: "app-easy-form-checkbox",
  templateUrl: "./easy-form-checkbox.component.html",
  styleUrls: ["./easy-form-checkbox.component.scss"],
})
export class EasyFormCheckboxComponent implements OnInit {
  @ViewChild("input") public inputEl?: ElementRef;
  /**
   * Label of the input
   */
  @Input() public label: string = "";

  /**
   * REQUIRED ! HTML name/id of the input
   * @required
   */
  @Input() public checkboxName: string = "";

  /**
   * REQUIRED ! Form control fo the input
   * @required
   */
  @Input() public control: FormControl = new FormControl();

  /**
   * Errors map of the input
   * ex: [{'required': 'This field is required'}]
   */
  @Input() public errors: Map<string, string> = new Map<string, string>();

  /**
   * Size of the input
   */
  @Input() public size: "small" | "medium" = "medium";

  /**
   * If true, the input is read only
   */
  @Input() public readonly: boolean = false;

  /**
   * This event occurs everytime the value changes
   */
  @Output() public valueChange: EventEmitter<string> =
    new EventEmitter<string>();

  public isRequired: boolean = false;

  constructor() {}

  public ngOnInit() {
    const validator = this.control.validator?.call(this, {} as AbstractControl);
    if (validator && validator.required) {
      this.isRequired = true;
    }
  }
}
