import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: "app-card-footer-button",
  templateUrl: "./card-footer-button.component.html",
  styleUrls: ["./card-footer-button.component.scss"],
})
export class CardFooterButtonComponent implements OnInit {
  @Input()
  public buttonTitle: string = "";

  @Input()
  public disabled: boolean = false;

  @Input()
  public icon: string = "";

  @Input()
  public color: "danger" | "success" | "default" = "default";

  constructor() {}

  ngOnInit(): void {}

  public handleClick(event: any) {
    if (this.disabled) {
      event.stopPropagation();
      event.preventDefault();
    }
  }
}
