import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnChanges,} from '@angular/core';

@Component({
  selector: "app-easy-paginator",
  templateUrl: "./easy-paginator.component.html",
  styleUrls: ["./easy-paginator.component.scss"],
})
export class EasyPaginatorComponent implements OnInit, OnChanges {
  @Input()
  public pageCount: number = 1;

  @Input()
  public page: number = 1;

  @Output()
  public pageClick: EventEmitter<number> = new EventEmitter();

  public currentPage: number = 1;

  public pages: number[] = [];

  constructor() {}

  ngOnInit(): void {
    this.setPages();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.page) {
      this.currentPage = this.page;
      this.setPages();
    }

    if (changes.pageCount) {
      this.setPages();
    }
  }

  public handlePageClick(newPage: number) {
    this.currentPage = newPage;
    this.setPages();
    this.pageClick.emit(newPage);
  }

  private setPagesButtons(): void {
    this.pages = Array(5);
    let firstPage = this.currentPage - 2;

    if (firstPage < 1) {
      firstPage = 1;
    }

    this.pages[0] = firstPage;
    this.pages[1] = firstPage + 1;
    this.pages[2] = firstPage + 2;

    if (firstPage + 3 <= this.pageCount) {
      this.pages[3] = firstPage + 3;

      if (firstPage + 4 <= this.pageCount) {
        this.pages[4] = firstPage + 4;
      } else {
        this.pages = this.pages.slice(0, 4);
      }
    } else {
      this.pages = this.pages.slice(0, 3);
    }
  }

  private setPages(): void {
    if (this.pageCount > 7) {
      this.setPagesButtons();
    } else {
      this.pages = Array(this.pageCount).fill("1");

      this.pages = this.pages.map((page, index) => {
        return index + 1;
      });
    }
  }
}
