<div class="easy-form-checkbox input-container"
    [class.withErrors]="control.touched
    && control?.errors"
    [class.disabled]="control.disabled" [class.readonly]="readonly">

    <div style="position: relative;">

        <input type="checkbox"
            [name]="checkboxName"
            [id]="checkboxName" [formControl]="control" />

        <label *ngIf="label" [for]="checkboxName">
            <span>{{ label }}</span>
            <span *ngIf="isRequired" class="required-star">&nbsp;*</span>
        </label>
    </div>
    <app-easy-form-errors *ngIf="errors?.size> 0 && control.touched &&
        control?.errors"
        [errors]="errors" [control]="control">
    </app-easy-form-errors>
</div>