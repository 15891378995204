import {ElementRef, Injectable} from '@angular/core';

@Injectable({
  providedIn: "root",
})
export class DropdownHelperService {
  constructor() {}

  public focusFirstOption(): void {
    const firstOption = document.querySelector(
      "app-easy-select-recursive-option:first-child li"
    ) as HTMLElement;
    if (firstOption) {
      firstOption.focus();
    }
  }

  public focusLastOption(): void {
    const lastOption = document.querySelector(
      "app-easy-select-recursive-option:last-child li"
    ) as HTMLElement;
    if (lastOption) {
      lastOption.focus();
    }
  }

  public focusSelectedOption(): void {
    const optionToFocus = document.querySelector(
      "[aria-selected=true]"
    ) as HTMLElement;
    if (optionToFocus) {
      optionToFocus.focus();
    }
  }

  public handleButtonKeydown(event: KeyboardEvent): void {
    const key = event.key;
    switch (key) {
      case " ": {
        event.preventDefault();
        break;
      }
      case "ArrowUp": {
        event.preventDefault();
        break;
      }
      case "ArrowDown": {
        event.preventDefault();
        break;
      }
      default: {
        break;
      }
    }
  }

  public handleButtonKeyup(
    event: KeyboardEvent,
    readonly: boolean,
    context: any,
    listElementName: string,
    selectedOption: any | undefined,
    toggleFunction: Function,
    openFunction: Function,
    overflowFunction: Function
  ): void {
    const key = event.key;
    switch (key) {
      case " ": {
        toggleFunction();
        break;
      }
      case "Enter": {
        event.preventDefault();
        event.stopPropagation();
        toggleFunction();
        break;
      }
      case "ArrowUp": {
        event.preventDefault();
        event.stopPropagation();

        if (!readonly) {
          openFunction();

          setTimeout(() => {
            this.isOverflowing(context[listElementName], () => {
              overflowFunction();
            });

            if (selectedOption) {
              const optionToFocus = document.querySelector(
                "[aria-selected=true]"
              ) as HTMLElement;
              const previousSibling = optionToFocus?.parentElement
                .previousElementSibling as HTMLElement;

              if (previousSibling) {
                (previousSibling.firstElementChild as HTMLElement).focus();
              } else {
                this.focusLastOption();
              }
            } else {
              this.focusLastOption();
            }
          }, 100);
        }

        break;
      }
      case "ArrowDown": {
        event.preventDefault();
        event.stopPropagation();
        if (!readonly) {
          openFunction();
          setTimeout(() => {
            this.isOverflowing(context[listElementName], overflowFunction);

            if (selectedOption) {
              const optionToFocus = document.querySelector(
                "[aria-selected=true]"
              ) as HTMLElement;
              const nextSibling = optionToFocus?.parentElement
                .nextElementSibling as HTMLElement;
              if (nextSibling?.firstElementChild.tagName == "LI") {
                (nextSibling.firstElementChild as HTMLElement).focus();
              } else {
                this.focusFirstOption();
              }
            } else {
              this.focusFirstOption();
            }
          }, 0);
        }

        break;
      }
      default: {
        break;
      }
    }
  }

  public handleOptionKeyup(
    event: KeyboardEvent,
    clickFunction: Function,
    closeFunction: Function
  ): void {
    const key = event.key;
    switch (key) {
      case "Enter": {
        event.preventDefault();
        event.stopPropagation();
        clickFunction();
        break;
      }
      case "Home": {
        event.preventDefault();
        event.stopPropagation();
        this.focusFirstOption();
        break;
      }
      case "End": {
        event.preventDefault();
        event.stopPropagation();
        this.focusLastOption();
        break;
      }
      case "Escape": {
        event.preventDefault();
        event.stopPropagation();
        closeFunction();
        break;
      }

      default: {
        break;
      }
    }
  }

  public handleOptionKeydown(
    event: KeyboardEvent,
    optionId: string,
    closeFunction: Function
  ): void {
    const key = event.key;
    switch (key) {
      case " ": {
        event.preventDefault();
        break;
      }
      case "ArrowUp": {
        event.preventDefault();
        event.stopPropagation();
        const option = document.getElementById(optionId).parentElement;

        const previousSibling = option?.previousElementSibling as HTMLElement;

        const previousPreviousSibling =
          previousSibling?.previousElementSibling as HTMLElement;

        if (previousSibling?.firstElementChild.tagName == "LI") {
          (previousSibling?.firstElementChild as HTMLElement).focus();
        } else if (
          previousSibling?.tagName == "APP-EASY-SELECT-RECURSIVE-OPTION" &&
          previousPreviousSibling?.lastElementChild.tagName == "LI"
        ) {
          previousPreviousSibling.focus();
        } else {
          this.focusLastOption();
        }
        break;
      }
      case "ArrowDown": {
        event.preventDefault();
        event.stopPropagation();
        const option = document.getElementById(optionId).parentElement;

        const nextSibling = option?.nextElementSibling as HTMLElement;

        if (nextSibling?.firstElementChild.tagName == "LI") {
          (nextSibling.firstElementChild as HTMLElement).focus();
        } else if (
          nextSibling?.firstElementChild.tagName ==
          "APP-EASY-SELECT-RECURSIVE-OPTION"
        ) {
          (
            nextSibling.nextElementSibling.firstElementChild as HTMLElement
          ).focus();
        } else {
          this.focusFirstOption();
        }
        break;
      }
      case "Tab": {
        if (event.shiftKey) {
          const option = document.getElementById(optionId).parentElement;

          const previousSibling = option?.previousElementSibling as HTMLElement;
          if (
            previousSibling?.lastElementChild.tagName != "LI" &&
            previousSibling?.previousElementSibling?.lastElementChild.tagName !=
              "LI"
          ) {
            closeFunction();
          }
        } else {
          const option = document.getElementById(optionId).parentElement;

          const nextSibling = option?.nextElementSibling as HTMLElement;

          if (
            nextSibling?.firstElementChild.tagName != "LI" &&
            nextSibling?.firstElementChild.nextElementSibling?.tagName != "LI"
          ) {
            closeFunction();
          }
        }
        break;
      }
      case "Home": {
        event.preventDefault();
        event.stopPropagation();
        break;
      }
      case "End": {
        event.preventDefault();
        event.stopPropagation();
        break;
      }
      default: {
        break;
      }
    }
  }

  public isOverflowing(
    element: ElementRef | undefined,
    overflowFunction: Function
  ): void {
    const listRect = element?.nativeElement.getBoundingClientRect();
    if (listRect) {
      const listHeight = listRect.height;
      const listOffsetY = listRect.y;

      overflowFunction(listHeight + listOffsetY > window.innerHeight);
    }
  }
}
