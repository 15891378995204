import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: "app-easy-button",
  templateUrl: "./easy-button.component.html",
  styleUrls: ["./easy-button.component.scss"],
})
export class EasyButtonComponent implements OnInit {
  @Input()
  public type: "submit" | "button" = "button";

  @Input()
  public icon: string = "";

  @Input()
  public label: string = "";

  @Input()
  public ariaLabel: string = "";

  @Input()
  public color:
    | "primary"
    | "warning"
    | "danger"
    | "success"
    | "info"
    | "default" = "default";

  @Input()
  public endIcon: string = "";

  @Input()
  public buttonStyle: "raised" | "flat" | "borderless" = "raised";

  @Input()
  public disabled: boolean = false;

  @Input()
  public active: boolean = false;

  @Input()
  public buttonId: string = "";

  @Input()
  public size: "small" | "medium" = "medium";

  @Input()
  public fullWidth: boolean = false;
  @Input()
  public routerLink: string;

  @Output()
  public click: EventEmitter<void> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  public handleClick(event: any): void {
    event.stopPropagation();
    if (!this.disabled) {
      this.click.emit();
    }
  }
}
