import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: "app-card-body-infos",
  templateUrl: "./card-body-infos.component.html",
  styleUrls: ["./card-body-infos.component.scss"],
})
export class CardBodyInfosComponent implements OnInit {
  @Input()
  public label: string = "";

  @Input()
  public value: string = "";

  @Input()
  public color:
    | "default"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "transparent" = "default";

  constructor() {}

  ngOnInit(): void {}
}
