<div class="easy-table">

  <div class="top-controls">

      <div *ngIf="config.withSearch" class="searchInput">
          <app-easy-form-input [control]="searchInputControl" icon="fas
              fa-search" [inputName]="'search-input_' + tableId"
              [placeholder]="'COMMON.COMMON.SEARCH' | translate"
              (valueChange)="handleSearchKeyUp($event)">
          </app-easy-form-input>
      </div>

      <div class="filters">
          <app-easy-form-select *ngFor="let filter of displayedFilters"
              [control]="filter.control" [options]="filter.options"
              [label]="filter.label"
              [clearable]="true"
              [search]="filter.search"
              [selectName]="tableId + '_filter_' + filter.field"
              (valueChange)="handleFilterChange()">
          </app-easy-form-select>
      </div>

      <div class="control-buttons" *ngIf="config.buttons.length> 0">
          <app-easy-button *ngIf="config.buttons.includes('csv')"
              icon="fa-solid fa-file-csv"
              [ariaLabel]="'COMMON.COMMON.CSV' | translate"
              (click)="handleExportCSV()">
          </app-easy-button>
          <app-easy-button *ngIf="config.buttons.includes('excel')"
              icon="fa-solid fa-file-excel"
              [ariaLabel]="'COMMON.COMMON.EXCEL' | translate"
              (click)="handleExportXLSX()">
          </app-easy-button>
          <app-easy-button *ngIf="config.buttons.includes('pdf')"
              icon="fa-solid fa-file-pdf"
              [ariaLabel]="'COMMON.COMMON.PDF' | translate"
              (click)="handleExportPDF()">
          </app-easy-button>
      </div>

      <div class="settings-button"
          *ngIf="config.userSettings?.columns?.length > 0">
          <app-easy-button icon="fas fa-cog"
              [buttonId]="tableId + '_settings'"
              [ariaLabel]="'COMMON.SETTINGS' | translate"
              (click)="handleSettingsClick()">
          </app-easy-button>
      </div>
  </div>

  <div class="table-container" [class.loading]="loadingData">
      <table #table [id]="tableId" [attr.aria-label]="options.ariaLabel"
          [attr.aria-rowcount]="entries?.length || 0">
          <!------------------------------------------ TABLE HEADER ------------------------------------------------------------->
          <thead>

              <tr class="headers">
                  <ng-container
                      *ngFor="let column of options.columns; let i= index">
                      <ng-container
                          *ngIf="canDisplayColumn(column.attributeName)">
                          <th
                              class="header"
                              [id]="tableId + '_header_' + (column.alias ?? column.attributeName)"
                              [class.header-icon]="column.type === 'icon'"
                              [class.defaultCursor]="!config.sortable ||
                      column.disableSort || (column.type !== 'text' && column.type !== 'icon')"
                              [style.maxWidth]="column.maxWidth"
                              [style.minWidth]="column.minWidth || '140px'"
                              [style.width]="column.width || 'auto'"
                              [attr.aria-sort]="config.sortBy == column.alias || config.sortBy == column.attributeName
                      ?
                      (config.sortOrder === 'asc' ? 'ascending' : 'descending')
                      :
                      null"
                              (click)="handleHeaderClick(column, i)">
                              <div
                                  style="display: flex; min-width: fit-content;
                          justify-content: space-between;
                          align-items: center;">

                                  <div class="header-label">{{ column.label |
                                      translate }}</div>
                                  <ng-container *ngIf="config.sortable &&
                              !column.disableSort && (column.type === 'text' || column.type === 'icon')">
                                      <div class="icon-container"
                                          [class.visible]="options.config.sortBy ==
                                  column.alias || config.sortBy == column.attributeName"
                                          [class.rotate]="options.config.sortOrder ===
                                  'asc'" [attr.aria-hidden]="true">
                                          <span
                                              class="fas fa-chevron-down"></span>
                                      </div>
                                  </ng-container>
                              </div>
                          </th>
                      </ng-container>
                  </ng-container>

              </tr>
          </thead>

          <!------------------------------------------ TABLE BODY ------------------------------------------------------------->
          <tbody class="body">

              <tr *ngFor="let entry of displayedEntries ; let i= index;"
                  class="entry" [attr.aria-rowindex]="i+1"
                  [id]="tableId + '_row_'+i"
                  [ngClass]="config.rowClassCallback ?
                  config.rowClassCallback(entry) : []"
                  [class.pointer]="config.rowCallback"
                  (click)="config.rowCallback ? config.rowCallback(entry) :
                  null">
                  <ng-container *ngFor="let column of options.columns">
                      <ng-container
                          *ngIf="canDisplayColumn(column.attributeName)">

                          <!------------------------------------------ TEXT CELL ------------------------------------------------------------->
                          <td *ngIf="column.type === 'text'"
                              class="entry-value"
                              [class.ellipsis]="column.ellipsis"
                              [class.pointer]="column.callback != undefined"
                              [title]="column.title ?? getCellValue(entry,
                          column.attributeName)"
                              [style.color]="column.color ? column.color(entry) :
                          ''"
                              [ngClass]="[column.color ? column.color(entry) :
                          'default',
                          (column.classCallback ?
                          spreadClasses(column.classCallback(entry)) : '')]"
                              [style.maxWidth]="column.maxWidth"
                              [style.minWidth]="column.minWidth || '140px'"
                              [innerHtml]="getCellValue(entry,
                          column.attributeName)"
                              (click)="handleCellClick(column, entry)">
                          </td>

                          <!------------------------------------------ ICON CELL ------------------------------------------------------------->
                          <td *ngIf="column.type === 'icon'" class="entry-value
                          icon-value"
                              [class.pointer]="column.callback != undefined"
                              [style.color]="column.color ? column.color(entry) :
                          ''"
                              [ngClass]="column.color ? column.color(entry) :
                          'default'"
                              [style.maxWidth]="column.maxWidth"
                              [style.minWidth]="column.minWidth || '140px'"
                              (click)="handleCellClick(column, entry)"
                              [title]="column.title | translate">
                              <span *ngIf="!exporting" class="icon"
                                  [ngClass]="column.icon(entry) ?
                              column.icon(entry)
                              : ''"></span>
                              <span *ngIf="exporting">{{getCellValue(entry,
                                  column.attributeName)}}</span>
                          </td>
                          <!------------------------------------------ CHECKBOX CELL ------------------------------------------------------------->
                          <td *ngIf="column.type === 'checkbox'"
                              class="entry-value
                          checkbox-value"
                              [class.pointer]="column.callback != undefined"
                              [ngClass]="column.color ? column.color(entry) :
                          'default'"
                              [style.maxWidth]="column.maxWidth"
                              [style.minWidth]="column.minWidth || '140px'"
                              [title]="column.title | translate"
                              (click)="handleCellClick(column, entry)">
                              <div class="checkbox flex">
                                  <input #input type="checkbox"
                                      [name]="column.attributeName + '_' + i"
                                      [checked]="entry[column.attributeName]"
                                      [id]="column.attributeName + '_' + i"
                                      tabindex="-1" />
                                  <div class="check-container h-4 w-4"
                                      (keyup)="handleCheckboxKeyUp($event, column,
                                  entry)"
                                      (keydown)="handleCheckboxKeyDown($event)"
                                      role="checkbox" [tabindex]="0"
                                      [class.checked]="getCellValue(entry,
                                  column.attributeName) == true"
                                      [attr.aria-checked]="getCellValue(entry,
                                  column.attributeName) == true">
                                      <span *ngIf="getCellValue(entry,
                                      column.attributeName) == true"
                                          class="fas
                                      fa-check">
                                      </span>
                                  </div>
                              </div>
                          </td>
                      </ng-container>

                  </ng-container>

              </tr>

          </tbody>

          <!------------------------------------------ LOADER ------------------------------------------------------------->
          <div *ngIf="loadingData" class="loader">
              <app-easy-loader></app-easy-loader>
          </div>

      </table>

      <!------------------------------------------ NO DATA MESSAGE ------------------------------------------------------------->
      <div *ngIf="config.noDataMessage && !loadingData &&
          displayedEntries.length
          == 0 && entries.length> 0" class="no-data">
          {{ config.noDataSearchMessage | translate }}
      </div>

      <div *ngIf="config.noDataMessage && !loadingData && entries.length == 0"
          class="no-data">
          {{ config.noDataMessage | translate }}
      </div>

  </div>

  <!------------------------------------------ PAGINATION ------------------------------------------------------------->
  <div *ngIf="config.pageSize> 0 && config.totalCount > 0 &&
      displayedEntries.length > 0"
      class="pagination">
      <!------------------------------------------ PAGE SIZE CONTROL ------------------------------------------------------------->
      <div class="left">
          <span>
              {{ 'COMMON.COMMON.PAGINATION_ITEMS_DISPLAYED' | translate:{
              "START":(config.page - 1) * config.pageSize + 1,
              "END":getCount(),
              "TOTAL":config.totalCount} }}.
          </span>
          <ng-container *ngIf="config.pageSizeControl">
              <span>{{ 'COMMON.COMMON.PAGINATION_SIZE_LABEL' | translate
                  }}</span>
              <app-easy-form-select
                  [selectName]="tableId + '_pagesize'"
                  [control]="pageSizeControl" [options]="pageSizeOptions"
                  [alwaysAbove]="true"
                  (valueChange)="handlePageSizeChange()">
              </app-easy-form-select>
          </ng-container>
      </div>

      <div class="right">
          <app-easy-paginator *ngIf="pageCount> 1" [pageCount]="pageCount"
              [page]="config.page"
              (pageClick)="handlePageClick($event)">
          </app-easy-paginator>
      </div>
  </div>
</div>

<app-easy-modal [fitWidth]="true" [modalTitle]="modalTitle | translate"
  [open]="modalOpen"
  [overlayClose]="!loadingExport"
  (close)="loadingExport ? null : modalOpen=false;">
  <div class="export-modal">
      <div class="export-text">
          {{ 'WORKFLOW.GLOBAL.EXPORT_QUESTION' | translate }}
      </div>
      <app-easy-form-select [control]="printControl" [clearable]="false"
          [options]="printOptions" [search]="false">
      </app-easy-form-select>
      <div class="progress-bars">
          <app-easy-progress-bar *ngIf="loadingExport || exportError"
              label="Téléchargement des données"
              [progress]="exportProgress" [error]="exportError">
          </app-easy-progress-bar>
          <app-easy-progress-bar *ngIf="exportData"
              label="Exportation des données"
              [progress]="exportDataProgress">
          </app-easy-progress-bar>
      </div>
  </div>

  <div class="export-modal-footer" footer>
      <app-easy-button [disabled]="loadingExport" color="default"
          [label]="'COMMON.COMMON.CANCEL' | translate"
          (click)="this.modalOpen = false;"></app-easy-button>
      <app-easy-button [disabled]="loadingExport" color="success"
          [label]="'COMMON.COMMON.EXPORT' | translate"
          (click)="handleExport()"></app-easy-button>
  </div>
</app-easy-modal>

<app-easy-modal [fitWidth]="true"
  [modalTitle]="'GLOBAL.GLOBAL.SETTINGS' | translate"
  [open]="settingsModalOpen" [overlayClose]="false"
  (close)="handleSaveUserSettings()">
  <div class="settings-modal">
      <div class="settings-column">
          <div class="column-header">Columns</div>
          <div class="form-group"
              *ngFor="let column of columnSettings">
              <app-easy-form-checkbox
                  [control]="column.control" [label]="column.label"
                  [checkboxName]="tableId + '_user_settings_column_' + (column.alias ?? column.name)">
              </app-easy-form-checkbox>
          </div>
      </div>
  </div>
  <div class="settings-modal-footer" footer>
      <app-easy-button [buttonId]="tableId + '_user_settings_confirm'"
          [disabled]="loadingUserSettings" color="success"
          [label]="'COMMON.COMMON.CONFIRM' | translate"
          (click)="handleSaveUserSettings()">
      </app-easy-button>
  </div>
</app-easy-modal>
