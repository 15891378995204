<div class="animation-container">
    <div class="easy-alert" [ngClass]="[type]" [class.wiggle]="wiggle">
        <div class="easy-alert-icon">
            <i *ngIf="type === 'success'" class="fas fa-check-circle"></i>
            <i *ngIf="type === 'info'" class="fas fa-question-circle"></i>
            <i *ngIf="type === 'warning'" class="fas fa-exclamation-triangle"></i>
            <i *ngIf="type === 'danger'" class="fas fa-times-circle"></i>
        </div>
        <div class="easy-alert-content">
            <ng-content></ng-content>
        </div>
    </div>
</div>