<div class="col-6 offset-3">
    <div class="card">
        <div class="card-header">
            <span class="card-title">Terms of Use</span>
        </div>
        <div class="card-body" id="container-terms">
            <p>
                Please read these Terms of Use and Bridgestone’s privacy policy (together,
                these <b>“Terms”</b>) carefully as they together with the Agreement form a
                contract with Bridgestone and govern the use of and access to the Service
                and Websites whether in connection with a paid subscription to the Service
                or a free trial of the Service. In the event of a conflict between these
                terms of service and Bridgestone’s privacy policy, these terms of service
                shall prevail. <br />
                By accessing or using the Services or the System, or authorizing or
                permitting any Authorized User to access or use the Service, Customer agrees
                to be bound by these Terms. Bridgestone may update the Services from time to
                time and may change content at any time.
            </p>

            <h1>Definitions</h1>
            <p>
                The following terms have the meanings given below:
            </p>
            <p>
                <b>“Affiliate”</b> means an entity that is Controlled by, Controls, or is
                under common Control with such party.
            </p>
            <p>
                <b>“Agreement”</b> means an individual for whom Buyer or an Affiliate has
                purchased a subscription to use the Services and identified by a valid
                e-mail address tied to an active user account. Authorized Users may include
                those employees, contractors and agents, as applicable, named by Buyer to
                use the Services in accordance with this Agreement. The number of Authorized
                Users authorized to access the Services for which Buyer has paid applicable
                fees are specified in the Agreement.
            </p>
            <p>
                <b>“Authorized User(s)”</b> means an individual for whom Buyer or an
                Affiliate has purchased a subscription to use the Services and identified by
                a valid e-mail address tied to an active user account. Authorized Users may
                include those employees, contractors and agents, as applicable, named by
                Buyer to use the Services in accordance with this Agreement. The number of
                Authorized Users authorized to access the Services for which Buyer has paid
                applicable fees are specified in the Agreement.
            </p>
            <p>
                <b>“Buyer”</b> means the company or other legal entity that has entered into
                the Agreement with Bridgestone.
            </p>
            <p>
                <b>“Control”</b> means the ability, directly or indirectly, to direct the
                affairs of another by means of ownership, contract or otherwise.
                <b>“Customer”</b>
                means the Buyer and the Authorized Users together.
            </p>
            <p>
                <b>“Customer Material(s)”</b> means data in electronic form input or
                collected through the Services by or from Customer, including all Customer
                data stored in the System. Customer Material excludes Third Party Content
                and Third Party Applications.
            </p>
            <p>
                <b>“Documentation”</b> means any materials that pertain to the Services and
                made available by Bridgestone, including help menus or user guides, as
                updated from time to time.
            </p>
            <p>
                <b>“Force Majeure Event(s)”</b> means delay, failure or default, other than
                a failure to pay fees when due will constitute a breach of this Agreement to
                the extent arising, directly or indirectly, out of causes reasonably beyond
                the direct control or foreseeability of a party, including but not limited
                to acts of God, fire, explosion, accident, strike, labor slowdowns or
                interruption, internet service provider failure, denial of service, delay or
                failure of contractors or common carriers, civil commotion, war, acts of
                terrorism, embargo or governmental acts or regulations.
            </p>
            <p>
                <b>“Intellectual Property Rights”</b> includes, without limitation, all
                rights with respect to copyright, moral rights, trademark, trade secret,
                patent, rights of publicity or rights of privacy.
            </p>
            <p>
                <b>“Personal Data”</b> means the name, e-mail address, telephone number and
                business address of an individual obtained or maintained by Bridgestone
                solely to deliver the Services under the Agreement and these Terms.
            </p>
            <p>
                <b>“Proprietary Information”</b> means all proprietary and nonpublic
                information disclosed by either party (the “disclosing party”) to the other
                (the “receiving party”), orally or in writing, that is designated as
                confidential or that reasonably should be understood to be confidential
                given the nature of the information and circumstance of the disclosure,
                including but not limited to: <br />(i) information learned by the receiving
                party from the disclosing party’s employees or agents or obtained or
                observed by the receiving party in connection with the provision of the
                services hereunder, <br />(ii) information that relates to the disclosing
                party’s trade secrets and businesses, including its products, solutions,
                plans, software, designs, technology, operations, processes, business and
                marketing plans, business opportunities, research, know-how, trade secrets,
                personnel, agreements, financial information, advertisements, marketing
                plans, advertising plans, sales, customers, prospective customers,
                publications of any sort or nature, forecasts, analyses, compilations,
                statistics, summaries, studies, notes, interpretations, other materials or
                information, and any materials based thereon and prepared by either party,
                <br />(iii) third-party confidential information disclosed by either party
                to the other, and <br />(iv)the terms and conditions of this Agreement and
                Documentation. Customer’s Proprietary Information includes Customer
                Material. Bridgestone’s Confidential Information includes the Services, the
                System and Third Party Content.
            </p>
            <p>
                <b>“Scheduled Maintenance”</b> means updates to the Services or the System
                that provide additional features and security patches.
            </p>
            <p>
                <b>“Services”</b> means the Bridgestone offerings provided by Bridgestone.
                Services specifically exclude Third Party Content and Third Party
                Applications.
            </p>
            <p>
                <b>“Services Term”</b> means the period of time for which Buyer ordered
                Services.
            </p>
            <p>
                <b>“System”</b> means the combination of hardware and software components
                owned or licensed by Bridgestone to which Bridgestone grants Customer access
                as part of the Services.
            </p>
            <p>
                <b>“Technical Support”</b> shall mean technical support services Bridgestone
                agrees to provide as part of the Services subject to terms hereof.
            </p>
            <p>
                <b>“Third Party Application”</b> means web-based or software application
                provided by Customer or for Customer by a third party and interoperates with
                the Services or the System.
            </p>
            <p>
                <b>“Third Party Content”</b> means information obtained or derived from
                third party content providers or publicly available sources and made
                available to Customer through, within or in relation to the Services.
            </p>

            <h2>Article 1 – Use of the System and Services</h2>
            <ol class="article1">
                <li>
                    For the duration of the Services Term and subject to Buyer’s payment
                    obligations, Customer has the right to access the Services and use the
                    System for Customer’s internal business operations only and, in all cases,
                    subject to the Agreement, the Documentation and these Terms. The Services
                    and the System shall be accessed only by Authorized Users. Account sharing
                    is expressly prohibited and shall be a material breach of the Agreement
                    and these Terms.
                </li>
                <li>
                    For the duration of the Services Term and subject to Buyer’s payment
                    obligations, Customer has the right to access the Services and use the
                    System for Customer’s internal business operations only and, in all cases,
                    subject to the Agreement, the Documentation and these Terms. The Services
                    and the System shall be accessed only by Authorized Users. Account sharing
                    is expressly prohibited and shall be a material breach of the Agreement
                    and these Terms.
                </li>
                <li>
                    Bridgestone shall have the right to review Customer’s use of the Services
                    from time to time as is reasonably necessary for Bridgestone to ensure
                    compliance with the terms of the Agreement and these Terms.
                </li>
                <li>
                    Nothing in the Agreement and these Terms restricts Bridgestone from making
                    changes or updates to the System or the Services during the Services Term
                    to reflect changes in technology, industry practices, patterns of system
                    use and availability of Third Party Content, provided that any such
                    changes will not result in a material reduction in the level of
                    performance or availability of the Services provided to Customer during
                    the Services Term. Such changes or updates may include changes to
                    Bridgestone’s backend infrastructure, security, technical configurations
                    and application features.
                </li>
                <li>
                    Bridgestone shall provide Technical Support for the Services pursuant to
                    the terms of Bridgestone Standards.
                </li>
                <li>
                    Customer agrees that it is not executing the Agreement contingent on the
                    availability of any future services, functionality, programs or updates.
                </li>
            </ol>

            <h2>Article 2 – Customer responsibilities and restrictions</h2>
            <ol class="article2">
                <li>
                    Customer will comply with the Agreement, Documentation and these Terms,
                    use the Services or the System only in accordance with the Agreement,
                    Documentation, these Terms and applicable laws and government regulations,
                    and comply with the terms of service of any Third Party Content or Third
                    Party Application with which Customer uses the Services or the System.
                </li>
                <li>
                    Customer will comply with the Agreement, Documentation and these Terms,
                    use the Services or the System only in accordance with the Agreement,
                    Documentation, these Terms and applicable laws and government regulations,
                    and comply with the terms of service of any Third Party Content or Third
                    Party Application with which Customer uses the Services or the System.
                </li>
                <li>
                    Customer may not, nor may Customer permit any third party to, loan, lease,
                    distribute, transfer or make available the Services, the System or any
                    Documentation to any third party (other than the Authorized User(s)), nor
                    modify or remove any proprietary rights notices in Services or the System,
                    decompile, disassemble, reverse engineer or otherwise attempt to create
                    the source code for the Services or the System. Except as necessary for
                    the use of Services or the System, no downloading, copying or distribution
                    of the Services or the System, in whole or in part, is permitted. The
                    Services or the System shall be accessed only by Authorized Users.
                </li>
            </ol>

            <h2>Article 3 – Intellectual property</h2>
            <ol class="article3">
                <li>
                    All Intellectual Property Rights (a) in the Services, the System and the
                    Documentation provided for use hereunder, (b) created in the provision of
                    any Scheduled Maintenance or Technical Support, and (c) in any of the
                    Bridgestone’s Proprietary Information shall remain the exclusive property
                    of Bridgestone or its licensors, as appropriate.
                </li>
                <li>
                    Customer shall have no right, title or interest in the Services or System
                    in excess of the scope or duration of the Services. Unless stipulated
                    otherwise and/or terminated early for any reason whatsoever, Customer’s
                    right to access and use the Services and the System shall terminate at the
                    end of the Services Term.
                </li>
                <li>
                    To the extent that Customer downloads software in relation to the use of
                    the Services, the Customer receives from Bridgestone a non-exclusive
                    license to use this software during the Services Term. This license will
                    be restricted by the terms of the Agreement and these Terms.
                </li>
                <li>
                    Customer also grants Bridgestone the right to use, process and transmit
                    the Customer Material that is not Personal Data, for the purpose of
                    research, evaluation, product improvement, statistics, market research and
                    any other related purpose. This authorization is not limited in time and
                    not linked to the Services Term.
                </li>
            </ol>

            <h2>Article 4 – Confidentiality</h2>
            <ol class="article4">
                <li>
                    Proprietary Information may be used only to the extent required to perform
                    the Services, and will not be used for any other purpose without the
                    express prior written consent of the disclosing party, to be given or
                    withheld in the disclosing party’s absolute discretion. Each party further
                    agrees that it will not use any Proprietary Information of the other for
                    any purpose or in any manner that would constitute a violation of any laws
                    or regulations.
                </li>
                <li>
                    Each party agrees to maintain all Proprietary Information in confidence
                    and, except as specifically permitted herein, not to disclose Proprietary
                    Information of the other to any third party without the express prior
                    written consent of the disclosing party, to be given or withheld in the
                    disclosing party’s absolute discretion.
                </li>
                <li>
                    Each party agrees to disclose Proprietary Information only to those of its
                    and its Affiliates’ employees, agents, consultants and contractors
                    (collectively, “Representatives”) who are directly involved in the
                    performance of its obligations under the Agreement or these Terms and have
                    a need to access such Proprietary Information in connection therewith.
                    Bridgestone and Customer will each advise their Representatives of the
                    confidential nature of the Proprietary Information and agree to be
                    responsible for any breach of the provisions of the Agreement or these
                    Terms by such Representatives.
                </li>
                <li>
                    Notwithstanding the foregoing, Proprietary Information shall not include
                    information that (a) is now, or hereafter becomes, generally known or
                    available through no violation of the Agreement or these Terms; (b) is
                    known by the receiving party, without restriction on use or disclosure, at
                    the time of receiving such information; (c) is hereafter furnished to the
                    receiving party by a third party, as a matter of legal right and without
                    restriction on disclosure; (d) is independently developed by the receiving
                    party without reference to any Proprietary Information of the disclosing
                    party; or (e) is required to be disclosed by applicable law, rule,
                    regulation or court order, provided that the receiving party will use
                    reasonable efforts to give advance notice to and cooperate with the
                    disclosing party in connection with any such disclosure, and provided
                    further that the receiving party will limit such disclosure to only that
                    information that is legally required to be disclosed.
                </li>
                <li>
                    Bridgestone and Customer acknowledge that a breach of these
                    confidentiality provisions may result in serious and irreparable harm to
                    the disclosing party for which there is no adequate remedy at law. In the
                    event of such a breach, the disclosing party shall be entitled to seek any
                    temporary or permanent injunctive or other equitable relief in addition to
                    any monetary damages hereunder.
                </li>
                <li>
                    Bridgestone has not agreed to and does not agree to treat as confidential
                    any industry engagement or suggestion or idea provided by Customer
                    (“Feedback”), and nothing in the Agreement or these Terms restricts
                    Bridgestone’s right to use, profit from, disclose, publish, or otherwise
                    exploit any Feedback, without compensation. Feedback does not include
                    Customer Proprietary Information as defined in these Terms.
                </li>
            </ol>

            <h2>Article 5 – Data Protection</h2>
            <ol class="article5">
                <li>
                    Bridgestone shall maintain security practices in accordance with general
                    industry standards designed to prevent any unauthorized compromise of
                    Customer Materials. Such security practices may include (a) firewall and
                    antivirus software, (b) the maintenance of operating systems and other
                    applications with up-to-date virus definitions and security patches, (c)
                    access controls (and changes thereto) for new, current and former
                    Bridgestone users of the Services or the system, (d) password parameters
                    and/or (e) access controls for updating directories, files and databases.
                </li>
                <li>
                    Customer shall be responsible for authorizing and approving all access to
                    the Services or the System for its Authorized Users. Customer shall be
                    solely responsible for ensuring the security and confidentiality of all
                    user IDs and passwords. Customer shall promptly notify Bridgestone of any
                    suspected theft, loss or fraudulent use of such user IDs or passwords.
                    Customer is responsible for all actions and access to the Services, the
                    System and Customer Materials effected through use of any user ID, and any
                    transactions effected under a user ID will be deemed to have been
                    performed by Customer. Bridgestone shall have no liability for any
                    unauthorized access to or use of the Services or the System or for any
                    alteration, theft or destruction of any content, including Customer
                    Materials, accessible through use of the Services or the System to the
                    extent attributable to access to the Services or the System via Customer’s
                    portal.
                </li>
            </ol>

            <h2>Article 6 – Personal information</h2>
            <p class="mb-3">
                Except for Personal Data for which Customer has procured all necessary
                consent from the owners thereof, Customer shall not, and shall not allow
                others to, include in any Customer Materials or otherwise store in or upload
                to the Services or the System, and Bridgestone’s obligations under the
                Agreement shall not extend to, any information that discloses information
                about individuals that is protected by law or regulation, including but not
                limited to Social Security numbers, credit information, or health
                information or any information that is protected under the relevant
                regulations.
            </p>

            <h2>Article 7 – Representations and warranties</h2>
            <ol class="article7">
                <li>
                    Bridgestone does not warrant that (a) the Services will be performed
                    completely error-free or uninterrupted, or that Bridgestone will correct
                    all services errors, (b) the Services will operate in combination with
                    Customer Materials or Customer’s applications, or with any other hardware,
                    software, systems or data not provided by Bridgestone, and (c) the
                    Services will meet Customer’s requirements, specifications or
                    expectations. Customer acknowledges that Bridgestone does not control the
                    transfer of data over communications facilities, including the internet,
                    and that the Services and access to the System may be subject to
                    limitations, delays, and other problems inherent in the use of such
                    communications facilities. Bridgestone is not responsible for any delays,
                    delivery failures, or other damage resulting from such problems.
                </li>
                <li>
                    Bridgestone does not make any representation or warranty regarding the
                    reliability, accuracy, completeness, correctness, or usefulness of Third
                    Party Content or Third Party Applications, and disclaims all liabilities
                    arising therefrom.
                </li>
                <li>
                    Customer accepts full responsibility for any use or storage of Customer
                    Materials in connection with the Services, including any infringement of a
                    third party’s rights resulting from such use or storage.
                </li>
                <li>
                    Except as expressly provided in this article 7, neither party makes any
                    warranty of any kind, whether express, implied, statutory or otherwise,
                    and each party specifically disclaims all implied warranties, including
                    any implied warranty of merchantability, fitness for a particular purpose
                    or non-infringement, to the maximum extent permitted by applicable law.
                    Except as specifically set forth in this article 7, the services, system,
                    documentation, standards and other materials or information are provided
                    “as is,” exclusive of any warranty whatsoever. Each party disclaims all
                    liability and indemnification obligations for any harm or damages caused
                    by any third-party hosting or application providers.
                </li>
                <li>
                    To the extent not prohibited by law, these warranties are exclusive and
                    there are no other express or implied warranties or conditions including
                    for services, system, software, documentation, hardware, networks or
                    environments or for merchantability, satisfactory quality and fitness for
                    a particular purpose.
                </li>
                <li>
                    On occasion, Bridgestone may make available certain Services for trial,
                    non-production purposes. Services used for trial purposes are provided on
                    an “as is” and “as available” basis without warranty, and may be subject
                    to additional terms and conditions. Unless otherwise expressly agreed by
                    the parties, such trial services may not be used with production data that
                    has not been masked, anonymized or otherwise rendered unreadable.
                    Bridgestone does not provide technical or other support or offer any
                    warranties for such services.
                </li>
            </ol>

            <h2>Article 8 – Limitation of liability</h2>
            <ol class="article8">
                <li>
                    To the extent not prohibited by law, in no event shall the aggregate
                    liability of each party together with all of its affiliates arising or
                    related to the agreement exceed the total amount of fees paid by customer
                    and its affiliates hereunder for the services under which the claim arose
                    in the twelve-month period preceding the date such claim arose. The
                    foregoing limitation will apply whether an action is in contract or tort
                    and regardless of the theory of liability, but will not limit customer’s
                    and customer’s affiliates’ payment obligations under Article 8 of the
                    Agreement.
                </li>
                <li>
                    To the extent not prohibited by law, in no event will either party or its
                    affiliates be liable for any indirect, special or consequential damages
                    and/or lost revenues or profits (excluding fees under the agreement), lost
                    data, whether in action in contract or tort and regardless of theory of
                    liability, even if advised of the possibility of such damages.
                </li>
                <li>
                    The parties acknowledge that the limitations set forth in this article are
                    integral to the amount of consideration levied in connection with the
                    services and that, were BRIDGESTONE to assume any further liability other
                    than as set forth herein, such consideration would of necessity be set
                    substantially higher.
                </li>
            </ol>

            <h2>Article 9 – Technology export</h2>
            <p class="mb-3">
                Customer shall not permit any third party to access or use the Services or
                the System in violation of the Agreement, these Terms, any law or
                regulation, or export any Services provided by BRIDGESTONE except in
                compliance with the applicable laws and regulations.
            </p>

            <h2>Article 10 – Force majeure</h2>
            <p class="mb-3">
                Neither party will be deemed in default of the Agreement or these Terms to
                the extent that performance of its obligations, or attempts to cure any
                breach are delayed or prevented by a Force Majeure Event(s). In the event a
                Force Majeure Event continues for more than ninety (90) days, the party
                affected by the Force Majeure Event may terminate the Agreement immediately
                upon written notice to the other party.
            </p>

            <h2>Article 11 – Governing law</h2>
            <p>
                The Agreement and these Terms are governed by the laws of Belgium. Customer
                agrees to submit to the exclusive jurisdiction of, and venue in the courts
                of Brussels in any dispute arising out of or related to the Agreement
                including without limitation tort claims.
            </p>
            <div class="custom-control custom-checkbox mt-3">
                <label class="container">I agree the <a data-bs-toggle="modal" data-bs-target="#termsOfUse"> Terms of Use
                    <i class="fas fa-external-link-alt px-1"></i></a>
                    <input type="checkbox" (change)="toggleCheck()">
                    <span class="checkmark"></span>
                </label>
            </div>
        </div>
        <div class="card-footer">
            <button type="button" id="left" class="btn btn-times decline" title="Disagree" (click)="logout()">
                <i class="fas fa-times fa-lg"></i> No, I don't agree
            </button>
            <button [disabled]="!isCheck" type="submit" id="right" class="btn btn-custom-check validate" title="Agree" (click)="checkTerms()">
                <i class="fas fa-lg fa-check"></i> Yes, confirm my choice
            </button>
        </div>
    </div>
</div>

<div class="modal fade" id="termsOfUse" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <span class="modal-title">Terms of Use</span>
                <button type="button" class="close btn-times" data-bs-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">
                        <i class="fas fa-times fa-lg"></i>
                    </span>
                </button>
            </div>
            <div class="modal-body">
                <ngx-extended-pdf-viewer [src]="pdfSrc" useBrowserLocale="true" [(sidebarVisible)]="sidebarVisible" zoom="page-fit">
                </ngx-extended-pdf-viewer>
            </div>
        </div>
    </div>
</div>
