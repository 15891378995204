<li [id]="selectName + 'option' + option.id"
    class="select-option py-2.5 px-3" [class.disabled]="option.disabled"
    [class.readonly]="!isClickable"

    [class.success]="option.color === 'success'" [class.warning]="option.color ===
    'warning'"
    [class.danger]="option.color === 'danger'"
    [class.info]="option.color === 'info'"
    (click)="isClickable && handleOptionClick()"
    [class.groupOptions]="isGroup"
    (keydown)="handleOptionKeydown($event)" (keyup)="handleOptionKeyup($event)"
    role="option" tabindex="0" [attr.aria-selected]="option.id ==
    selectedOptionId"
    [style.padding-left]="paddingLeft + 'rem'">

    <div *ngIf="option.icon" class="option-icon mr-2">
        <span class="icon" [ngClass]="option.icon"></span>
    </div>
    <div class="option-label ">{{ option.label }}</div>
    <div *ngIf="option.iconEnd" class="option-icon ml-2">
        <span class="icon" [ngClass]="option.iconEnd"></span>
    </div>

</li>
<ng-container *ngFor="let child of option.children">
    <app-easy-select-recursive-option [selectName]="selectName" [option]="child"
        [paddingLeft]="paddingLeft + 0.75"
        [clickableGroups]="clickableGroups"
        [selectedOptionId]="selectedOptionId"
        (optionClick)="handleOptionClick($event)"
        (optionKeydown)="handleOptionKeydown($event)"
        (optionKeyup)="handleOptionKeyup($event)">
    </app-easy-select-recursive-option>
</ng-container>