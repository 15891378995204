// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.azure.ts`.
// The list of file replacements can be found in `angular.json`.

const keycloakConfig = {
  url: "https://easytrack-demo-sso.aero.bridgestone",
  realm: "EasyTrack-Test",
  clientId: "easy-front",
};

export const environment = {
  baseUrl: "https://easytrack-demo-api.aero.bridgestone/api",
  baseWS: "wss://easytrack-demo-api.aero.bridgestone/api",
  production: false,
  emailFrom: "noreply@bridgestone-bae.com",
  emailTo: "solutions@bridgestone-bae.com",
  assets: {},
  keycloak: keycloakConfig,
  headers: { "Content-Type": "application/json" },
  redirectUri: "https://easytrack-demo-admin.aero.bridgestone/",
  babelApiUrl: "https://babel-api.aero.bridgestone",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
