import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnChanges,} from '@angular/core';

@Component({
  selector: "app-easy-modal",
  templateUrl: "./easy-modal.component.html",
  styleUrls: ["./easy-modal.component.scss"],
})
export class EasyModalComponent implements OnInit, OnChanges {
  @Input()
  public modalTitle: string = "";

  @Input()
  public open: boolean = false;

  @Input()
  public overlayClose: boolean = true;

  @Input()
  public fullHeight: boolean = false;

  @Input()
  public fitWidth: boolean = false;

  @Output()
  public close: EventEmitter<void> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.open) {
      if (this.open) {
        document
          .getElementsByTagName("BODY")[0]
          .setAttribute("overflow", "hidden");
      } else {
        document
          .getElementsByTagName("BODY")[0]
          .setAttribute("overflow", "auto");
      }
    }
  }

  public closeModal(fromOverlay: boolean = false): void {
    document.getElementsByTagName("BODY")[0].setAttribute("overflow", "auto");

    if ((fromOverlay && this.overlayClose) || !fromOverlay) {
      this.close.emit();
    }
  }
}
