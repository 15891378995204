import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {AdminService} from '../../../core/services/admin.service';

@Component({
  selector: 'app-check-terms',
  templateUrl: './check-terms.component.html',
  styleUrls: ['./check-terms.component.scss'],
})
export class CheckTermsComponent implements OnInit {
  @Output() isTermsCheckedEvent = new EventEmitter<boolean>();
  sidebarVisible: boolean = false;
  isCheck = false;
  pdfSrc: string;

  constructor(private router: Router, private adminService: AdminService) {
  }

  ngOnInit() {
    this.pdfSrc = '/assets/files/terms_of_use.pdf';
  }

  toggleCheck() {
    this.isCheck = !this.isCheck;
  }

  checkTerms() {
    this.adminService.updateEulaStatus().subscribe(() => {
      this.isTermsCheckedEvent.emit(true);
    });
  }

  logout() {
    this.isTermsCheckedEvent.emit(true);
    this.router.navigateByUrl('/logout');
  }
}
