import {Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {Observable} from 'rxjs';
import {EasytrackNotification, EasytrackNotificationType} from '../../../core/models/notification.model';
import {NotificationsService} from '../../../core/services/notifications.service';

@Component({
  selector: "app-header-notifications",
  templateUrl: "./header-notifications.component.html",
  styleUrls: ["./header-notifications.component.scss"],
})
export class HeaderNotificationsComponent implements OnInit {
  public open: boolean = false;

  private listener?: Function;

  private notifications$: Observable<EasytrackNotification[]> =
    this.notificationsService.notifications$;
  public notifications: EasytrackNotification[] = [
    {
      id: 1,
      creationDate: "Aujourd'hui",
      read: false,
      title: "Super notif",
      text: "C'est pour vous dire que vous avez une notif",
      link: "",
      type: EasytrackNotificationType.DEFAULT,
    },
    {
      id: 2,
      creationDate: "Aujourd'hui",
      read: false,
      title: "Super notif",
      text: "C'est pour vous dire que vous avez une notif",
      link: "",
      type: EasytrackNotificationType.DEFAULT,
    },
    {
      id: 3,
      creationDate: "Aujourd'hui",
      read: true,
      title: "Super notif",
      text: "C'est pour vous dire que vous avez une notif",
      link: "",
      type: EasytrackNotificationType.DEFAULT,
    },
    {
      id: 4,
      creationDate: "Aujourd'hui",
      read: true,
      title: "Super notif",
      text: "C'est pour vous dire que vous avez une notif",
      link: "",
      type: EasytrackNotificationType.DEFAULT,
    },
    {
      id: 5,
      creationDate: "Aujourd'hui",
      read: true,
      title: "Super notif",
      text: "C'est pour vous dire que vous avez une notif",
      link: "",
      type: EasytrackNotificationType.DEFAULT,
    },
    {
      id: 6,
      creationDate: "Aujourd'hui",
      read: true,
      title: "Super notif",
      text: "C'est pour vous dire que vous avez une notif",
      link: "",
      type: EasytrackNotificationType.DEFAULT,
    },
  ];

  public displayedNotifications: EasytrackNotification[] = [];

  public notReadCount: number = 0;

  public activeTab: "all" | "unseen" = "all";

  constructor(
    private eRef: ElementRef,
    private renderer: Renderer2,
    private notificationsService: NotificationsService
  ) {}

  ngOnInit(): void {
    this.getNotifications();
    this.notifications$.subscribe((notifications) => {
      this.notifications = notifications;
      this.setTabNotifications();
      this.notReadCount = this.notifications.filter((n) => !n.read).length;
    });
  }

  public toggleDropdown(): void {
    this.open = !this.open;
    if (this.open) {
      this.listener = this.renderer.listen("document", "click", (event) => {
        if (this.open && !this.eRef.nativeElement.contains(event.target)) {
          this.open = false;
        }
        if (!this.open) {
          this.listener();
        }
      });
    }
  }

  private setTabNotifications() {
    if (this.activeTab == "all") {
      this.displayedNotifications = this.notifications.slice();
    } else if (this.activeTab == "unseen") {
      this.displayedNotifications = this.notifications.filter((n) => !n.read);
    }
  }

  public handleTabClick(tab: "all" | "unseen") {
    this.activeTab = tab;
    this.setTabNotifications();
  }

  public getNotifications(): void {
    this.notificationsService.getNotifications().subscribe();
  }

  public toggleNotificationSeen(notification: EasytrackNotification): void {
    this.notificationsService
      .toggleNotificationSeen(notification.id)
      .subscribe();

    const notReadNotifs = this.notifications.filter((n) => !n.read);

    if (this.activeTab == "unseen") {
      this.displayedNotifications = notReadNotifs;
    }
    this.notReadCount = notReadNotifs.length;

    // this.notificationsService.addNotification(notification);
  }

  public handleNotificationClick(notification: EasytrackNotification) {
    if (!notification.read) {
      this.toggleNotificationSeen(notification);
    }

    // TODO : Handle notification
  }
}
