import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-module-home-button",
  templateUrl: "./module-home-button.component.html",
  styleUrls: ["./module-home-button.component.scss"],
})
export class ModuleHomeButtonComponent implements OnInit {
  @Input()
  public label: string = "";

  @Input()
  public icon: string = "";

  @Input()
  counters: any[] = [];

  constructor() {}

  ngOnInit(): void {}
}

export interface ModuleHomeButtonCounter {
  color: string;
  value: number;
}
