import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, tap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {MessageHandlerService} from './messageHandler.service';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  private serviceUrl: string = environment.baseUrl + '/documents/customer';

  constructor(
    private readonly http: HttpClient,
    private readonly messageService: MessageHandlerService
  ) {
  }

  getDocumentById(id) {
    return this.http
      .get<Blob>(this.serviceUrl + '/getDocumentById/' + id, {
        responseType: 'blob' as 'json',
      })
      .pipe(
        tap(
          () => {
          },
          () => {
            this.messageService.log(
              'Error',
              'Cannot Retrieve Certificate',
              3,
              true
            );
          }
        ),
        catchError(() => {
          return of(null);
        })
      );
  }

  public getDocumentsByBatchId(batchId: number): Observable<Document[]> {
    return this.http
      .get<Document[]>(
        environment.baseUrl +
        '/batches/customer/getDocumentsByBatchId/' +
        batchId,
        {responseType: 'json'}
      )
      .pipe(
        tap(
          () => {
          },
          () => {
            this.messageService.log(
              'Error',
              'Cannot Retrieve Documents',
              3,
              true
            );
          }
        ),
        catchError(() => {
          return of(null);
        })
      );
  }
}
