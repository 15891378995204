import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-stats-card',
  templateUrl: './stats-card.component.html',
  styleUrls: ['./stats-card.component.scss'],
})
export class StatsCardComponent {
  @Input()
  public icon: string;

  @Input()
  public subIcon: string = '';

  @Input()
  public subIconColor: 'default' | 'success' | 'danger' | 'warning' | 'info' =
    'default';

  @Input()
  public label: string = '';

  @Input()
  public value: number | string = '';
}
