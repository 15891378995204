import {Component, Input, OnInit} from '@angular/core';
import {SafeUrl} from '@angular/platform-browser';

@Component({
  selector: "app-carousel",
  templateUrl: "./carousel.component.html",
  styleUrls: ["./carousel.component.scss"],
})
export class CarouselComponent implements OnInit {
  @Input()
  public images: SafeUrl[] = [];

  public currentImageIndex: number = 0;

  constructor() {}

  ngOnInit(): void {}

  public handleNextPhoto(): void {
    if (this.currentImageIndex < this.images.length - 1) {
      this.currentImageIndex = this.currentImageIndex + 1;
    }
  }

  public handlePreviousPhoto(): void {
    if (this.currentImageIndex > 0) {
      this.currentImageIndex = this.currentImageIndex - 1;
    }
  }
}
