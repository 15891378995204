import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: "app-page-header-status",
  templateUrl: "./page-header-status.component.html",
  styleUrls: ["./page-header-status.component.scss"],
})
export class PageHeaderStatusComponent implements OnInit {
  @Input() public label: string = "";
  @Input() public color: string = "";

  @Input() public leftCallBack?: Function;
  @Input() public rightCallBack?: Function;

  constructor() {}

  ngOnInit(): void {}
}
