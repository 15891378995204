<div class="page-header-status">
    <ng-container *ngIf="leftCallBack">
        <app-easy-button buttonStyle="borderless"
            icon="fas fa-reply"
            [ariaLabel]="'COMMON.COMMON.REVOKE' | translate"
            (click)="leftCallBack()">
        </app-easy-button>
    </ng-container>

    <app-status-badge [label]="label" [color]="color">
    </app-status-badge>

    <ng-container *ngIf="rightCallBack">
        <app-easy-button buttonStyle="borderless"
            icon="fas fa-share"
            [ariaLabel]="'COMMON.COMMON.SEND' | translate"
            (click)="rightCallBack()">
        </app-easy-button>
    </ng-container>
</div>