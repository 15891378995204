<div class="header">
    <div class="logo">
        <img class="cropped img-fluid" src="../assets/img/brand.png" />
    </div>
    <div class="navigation">
        <app-header-item label="GLOBAL.GLOBAL.WORKFLOW" url="workflow"></app-header-item>
        <app-header-item label="GLOBAL.GLOBAL.ADMINISTRATION" url="admin"></app-header-item>
        <app-header-item label="GLOBAL.GLOBAL.HELP" url="help"></app-header-item>
    </div>
    <div class="language">
        <app-header-language></app-header-language>
    </div>
    <div class="dark-theme">
        <span *ngIf="darkTheme" class="fas fa-sun" (click)="toggleDarkTheme()"></span>
        <span *ngIf="!darkTheme" class="fas fa-moon" (click)="toggleDarkTheme()"></span>
    </div>
    <div class="notifications">
        <app-header-notifications></app-header-notifications>
    </div>
    <div *ngIf="(connectedUser$ | async) as user" class="account">
        <app-header-account [username]="user.username"></app-header-account>
    </div>
</div>