import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'objToKeys',
})
export class ObjectToKeysPipe implements PipeTransform {
  transform(value: any): string[] {
    if (!value) {
      return value;
    }
    return Object.keys(value);
  }
}
