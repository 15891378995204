<div class="easy-progress-bar" [class.error]="error"
    [class.success]="!error && progress == 100">
    <div class="label">
        <span>{{ label }}</span>
        <span class="label-progress">{{ progress }}%</span>
    </div>
    <div class="content">

        <div class="bar-container">
            <div class="bar" [style.width]="'calc(' + progress + '% + 5px)'"></div>

        </div>
        <div class="status">
            <span *ngIf="!error && progress == 100" class="far fa-circle-check"></span>
            <span *ngIf="error" class="far fa-circle-xmark"></span>
        </div>
    </div>

</div>