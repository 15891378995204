import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-status-badge",
  templateUrl: "./status-badge.component.html",
  styleUrls: ["./status-badge.component.scss"],
})
export class StatusBadgeComponent implements OnInit {
  @Input()
  public label: string = "";

  @Input()
  public color?: string;

  constructor() {}

  ngOnInit(): void {}
}
