import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: "app-ariane-string",
  templateUrl: "./ariane-string.component.html",
  styleUrls: ["./ariane-string.component.scss"],
})
export class ArianeStringComponent implements OnInit {
  @Input()
  pathes: ArianePath[] = [];

  constructor() {}

  ngOnInit(): void {}
}

export interface ArianePath {
  label: string;
  url: string;
}
