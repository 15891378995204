import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {EasySelectOption} from '../easy-form-select/easy-form-select.component';

@Component({
  selector: "app-easy-select-recursive-option",
  templateUrl: "./easy-select-recursive-option.component.html",
  styleUrls: ["./easy-select-recursive-option.component.scss"],
})
export class EasySelectRecursiveOptionComponent implements OnInit {
  @Input() public selectName: string = "";

  @Input() public option!: EasySelectOption;

  @Input() public paddingLeft!: number;

  @Input() public selectedOptionId?: string;

  @Input() public clickableGroups!: boolean;

  @Output() public optionClick: EventEmitter<EasySelectOption> =
    new EventEmitter<EasySelectOption>();

  @Output() public optionKeydown: EventEmitter<any> = new EventEmitter<any>();

  @Output() public optionKeyup: EventEmitter<any> = new EventEmitter<any>();

  public isClickable!: boolean;

  public isGroup!: boolean;

  public ngOnInit(): void {
    this.isClickable =
      (this.clickableGroups &&
        this.option.children &&
        this.option.children.length > 0) ||
      !this.option.children ||
      this.option.children.length === 0;
    this.isGroup = !!this.option.children && this.option.children.length > 0;
  }

  public handleOptionClick(option?: EasySelectOption): void {
    this.optionClick.emit(option ?? this.option);
  }

  public handleOptionKeydown(keyboardEvent: KeyboardEvent): void {
    this.optionKeydown.emit({ keyboardEvent, optionId: this.option.id });
  }

  public handleOptionKeyup(keyboardEvent: KeyboardEvent): void {
    this.optionKeyup.emit({ keyboardEvent, optionId: this.option.id });
  }
}

export interface SelectOptionKeyboardEvent {
  keyboardEvent: KeyboardEvent;
  optionId: string;
}
