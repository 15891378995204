import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: "app-card-header",
  templateUrl: "./card-header.component.html",
  styleUrls: ["./card-header.component.scss"],
})
export class CardHeaderComponent implements OnInit {
  @Input()
  public cardTitle: string = "";

  @Input()
  public url: string[] = [];

  @Input() color: string = "";

  constructor() {}

  ngOnInit(): void {}
}
