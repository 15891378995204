import {Component, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {Message} from '../../../core/models/message.model';
import {MessageService} from '../../../core/services/message.service';

@Component({
  selector: "app-message",
  templateUrl: "./message.component.html",
  styleUrls: ["./message.component.scss"],
})
export class MessageComponent implements OnDestroy {
  message: Message;
  subscription: Subscription;
  isClosed: boolean;

  constructor(private messageService: MessageService) {
    this.isClosed = false;
    // subscribe to home component messages
    this.subscription = this.messageService
      .getMessage()
      .subscribe((message) => {
        this.message = message;
        if (message) {
          setTimeout(() => {
            this.clearMessage();
          }, 3000);
        }
      });
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

  clearMessage() {
    this.isClosed = true;
    setTimeout(() => {
      this.messageService.clearMessage();
      this.isClosed = false;
    }, 1000);
  }
}
