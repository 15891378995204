import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: "app-page-header-actions",
  templateUrl: "./page-header-actions.component.html",
  styleUrls: ["./page-header-actions.component.scss"],
})
export class PageHeaderActionsComponent implements OnInit {
  @Input() public actions: PageHeaderAction[] = [];

  constructor() {}

  ngOnInit(): void {}
}

export interface PageHeaderAction {
  buttonId?: string;
  label: string;
  icon?: string;
  color?: string;
  callback: Function;
  disabled?: boolean;
}
