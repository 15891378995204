export interface EasytrackNotification {
  id: number;
  title: string;
  text: string;
  link: string;
  type: EasytrackNotificationType;
  creationDate: string;
  acknowledgementDate?: string;
  read: boolean;
}

export enum EasytrackNotificationType {
  DEFAULT = "Default",
}
