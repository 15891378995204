import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent {
  @Input() open: boolean = false;  // To control the visibility of the modal
  @Output() close = new EventEmitter<void>();  // To emit when the modal is closed
  @Output() deleteConfirmed = new EventEmitter<void>();  // To emit when delete is confirmed

  closeModal() {
    this.close.emit();
  }

  confirmDelete() {
    this.deleteConfirmed.emit();
  }
}
