import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {EasytrackNotification} from '../../../../core/models/notification.model';

@Component({
  selector: "app-easy-notification-toast",
  templateUrl: "./easy-notification-toast.component.html",
  styleUrls: ["./easy-notification-toast.component.scss"],
})
export class EasyNotificationToastComponent implements OnInit {
  @Input()
  public notification!: EasytrackNotification;

  @Output()
  public close: EventEmitter<void> = new EventEmitter();

  public isClosed: boolean = false;

  ngOnInit() {
    setTimeout(() => {
      this.handleCloseClick();
    }, 20000);
  }

  public handleCloseClick() {
    this.isClosed = true;
    setTimeout(() => {
      this.close.emit();
    }, 300);
  }
}
