import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: "app-easy-loader",
  templateUrl: "./easy-loader.component.html",
  styleUrls: ["./easy-loader.component.scss"],
})
export class EasyLoaderComponent implements OnInit {
  @Input()
  public fullscreen: boolean = false;

  public visible: boolean = false;

  constructor() {}

  ngOnInit(): void {
    setTimeout(() => {
      this.visible = true;
    }, 200);
  }
}
