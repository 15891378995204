<div id="headerLanguage" class="header-language" (click)="toggleDropdown()">

    <div class="toggle">
        <span class="fas fa-chevron-down icon"></span>
        <div class="flag-img" [innerHTML]="currentLanguage?.flagIcon"></div>
    </div>

    <div *ngIf="open" class="languages-dropdown">
        <div *ngFor="let lang of languages" class="language-item"
            [id]="'language_' + lang.key"
            (click)="handleLanguageClick(lang.key)">
            <div class="flag-text">{{ lang.name }}</div>
            <div class="flag-img" [innerHTML]="lang.flagIcon"></div>
        </div>
    </div>
</div>