export interface EasytrackHttpParams {
  limit?: number;
  page?: number;
  by?: string;
  order?: EasytrackSortOrder;
  search?: string;
}

export enum EasytrackSortOrder {
  ASC = "asc",
  DESC = "desc",
}
