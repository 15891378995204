<div class="easy-paginator">
    <div class="left-buttons">

        <app-easy-button [disabled]="currentPage <= 1"
            (click)="handlePageClick(currentPage - 1)" icon="fas
            fa-chevron-left">
        </app-easy-button>

        <app-easy-button *ngIf="pageCount> 7 && currentPage > 3"
            (click)="handlePageClick(1)" label="1">
        </app-easy-button>
    </div>
    <div class="pages-buttons">
        <app-easy-button *ngFor="let page of pages" [active]="currentPage ==
            page"
            (click)="handlePageClick(page)" [label]="page">
        </app-easy-button>
    </div>
    <div class="right-buttons">
        <app-easy-button *ngIf="pageCount> 7 && currentPage + 2 <pageCount"
                (click)="handlePageClick(pageCount)" [label]="pageCount">
            </app-easy-button>

            <app-easy-button [disabled]="currentPage>= pageCount"
                (click)="handlePageClick(currentPage + 1)" icon="fas
                fa-chevron-right">
            </app-easy-button>

        </div>
    </div>