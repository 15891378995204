<div class="header">
    <div *ngIf="backButton" class="left">
        <app-page-header-back-button [params]="backButtonParams"
            [backUrl]="backButtonUrl"></app-page-header-back-button>
    </div>
    <div class="right">
        <div class="title">
            <span>{{ pageTitle | uppercase }}</span>
            <div>
                <ng-content></ng-content>
            </div>
        </div>

        <div *ngIf="statusLabel" class="status-controls">
            <app-page-header-status [color]="statusColor" [label]="statusLabel"
                [leftCallBack]="leftStatusCallback"
                [rightCallBack]="rightStatusCallback">
            </app-page-header-status>
        </div>

        <div class="actions">
            <app-page-header-actions [actions]="actions"></app-page-header-actions>
        </div>
    </div>
</div>