import { Component, Input, OnInit, SimpleChanges, OnChanges } from "@angular/core";

@Component({
  selector: "app-status-card",
  templateUrl: "./status-card.component.html",
  styleUrls: ["./status-card.component.scss"],
})
export class StatusCardComponent implements OnInit, OnChanges {
  @Input()
  public active: boolean = false;

  @Input()
  public label: string = "";

  @Input()
  public count: number = 0;

  @Input()
  public color: string = "";

  public textColor: string = "";
  public backgroundColor: string = "";

  public hovered: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.setColors();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.active) {
      this.setColors();
    }
  }

  private setColors(): void {
    if (this.active) {
      this.textColor = "";
      this.backgroundColor = this.color;
    } else {
      this.textColor = this.color;
      this.backgroundColor = "";
    }
  }
}
