import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {MessageHandlerService} from './messageHandler.service';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'}),
  };

  private serviceUrl: string = environment.baseUrl + '/users/customer';

  constructor(
    private http: HttpClient,
    private messageService: MessageHandlerService
  ) {
  }

  /** PUT: Set the keycloack custom attribute eulaStatus to true to confirm that user have check terms of use */
  updateEulaStatus(): Observable<boolean> {
    return this.http
      .put<boolean>(this.serviceUrl + '/updateEula', this.httpOptions)
      .pipe(
        tap(() =>
          this.messageService.log(
            'validation',
            `Terms of use accepted`,
            1,
            true
          )
        ),
        catchError(
          this.messageService.handleError<boolean>('Terms of use validation')
        )
      );
  }
}
