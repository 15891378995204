import {Component, OnInit, OnDestroy} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {combineLatest, Observable, Subject} from 'rxjs';
import {takeUntil, tap} from 'rxjs/operators';
import {Language} from '../../../core/models/language.model';
import {LanguagesService} from '../../../core/services/languages.service';
import {ConnectedUser, UserLoggedService} from '../../../core/services/userLogged.service';

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
})
export class SettingsComponent implements OnInit, OnDestroy {
  public dateFormat: any[] = [
    { name: "English-US", tag: "en-US", field: "(M/DD/YYYY)" },
    { name: "European", tag: "eu-EU", field: "(DD/MM/YYYY)" },
    { name: "Japanese", tag: "ja-JP", field: "(YYYY/M/DD)" },
  ];
  public actualFormat: string = "";

  private connectedUser$: Observable<ConnectedUser> =
    this.userLogged.connectedUser$;

  public currentLanguage?: Language;

  private languages$: Observable<Language[]> = this.langService.languages$;

  public languages: Language[] = [];

  private onDestroy$: Subject<void> = new Subject();

  constructor(
    public translate: TranslateService,
    private userLogged: UserLoggedService,
    private langService: LanguagesService
  ) {}

  ngOnInit(): void {
    this.actualFormat = this.userLogged.getDateFormat();

    combineLatest([this.connectedUser$, this.languages$])
      .pipe(
        takeUntil(this.onDestroy$),
        tap(([user, languages]) => {
          this.languages = languages;
          this.currentLanguage =
            languages.find((l) => l.key == user.language) ?? languages[0];
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  changeLanguage(language: string) {
    this.translate.use(language);
    this.userLogged.updateLanguage(language).subscribe();
  }

  changeDateFormat(format: string) {
    this.userLogged.updateDateFormat(format).subscribe();
    this.actualFormat = format;
  }
}
