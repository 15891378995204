<div class="module-home-button">

    <div class="icon">
        <span [ngClass]="icon"></span>
    </div>
    <div class="title">
        <span>{{ label | translate | uppercase }}</span>
    </div>
    <div class="badges">
        <ng-container *ngFor="let counter of counters">

            <div *ngIf="counter.value> 0" class="badge"
                [style.background]="counter.color === 'warning' ? '' :
                counter.color"
                [ngClass]="counter.color">
                {{ counter.value }}
            </div>
        </ng-container>
    </div>
</div>