import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: "app-easy-alert",
  templateUrl: "./easy-alert.component.html",
  styleUrls: ["./easy-alert.component.scss"],
})
export class EasyAlertComponent implements OnInit {
  @Input()
  public type: "success" | "info" | "warning" | "danger" = "info";

  @Input()
  public wiggle: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
