import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {ConnectedUser, UserLoggedService} from '../../../core/services/userLogged.service';

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  public connectedUser$: Observable<ConnectedUser> =
    this.userLoggedService.connectedUser$;

  public darkTheme: boolean = false;

  constructor(private userLoggedService: UserLoggedService) {}

  ngOnInit(): void {
    this.connectedUser$.subscribe((user) => {
      this.darkTheme = user.darkTheme;
      if (this.darkTheme) {
        document.body.className = document.body.className + " dark";
      } else {
        document.body.className = document.body.className.replace(" dark", "");
      }
    });
  }

  public toggleDarkTheme() {
    this.userLoggedService.updateDarkTheme(!this.darkTheme).subscribe();
  }
}
