import {Pipe, PipeTransform} from '@angular/core';

import {DatePipe} from '@angular/common';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {ConnectedUser, UserLoggedService} from '../../core/services/userLogged.service';

@Pipe({
  name: 'LocalDate',
})
export class LocalDatePipe extends DatePipe implements PipeTransform {
  private connectedUser$: Observable<ConnectedUser> =
    this.userLoggedService.connectedUser$;

  private dateFormat: string = 'ja-JP';

  constructor(private userLoggedService: UserLoggedService) {
    super('en');
    this.connectedUser$
      .pipe(
        tap((user) => {
          this.dateFormat = user.dateFormat;
        })
      )
      .subscribe();
  }

  transform(date: Date): any {
    // If the format doesn't match any format registered in the dateFormat table, gets this one
    let format: string = 'yyyy/MM/dd, HH:mm:ss';

    if (this.dateFormat == 'ja-JP') format = 'yyyy/MM/dd, HH:mm:ss';

    if (this.dateFormat == 'en-US') format = 'MM/dd/yyyy, h:mm:ss a';

    if (this.dateFormat == 'eu-EU') format = 'dd/MM/yyyy, HH:mm:ss';

    return super.transform(date, format);
  }
}
