<div class="carousel-container">
    <div class="carousel">

        <div class="carousel-button"
            [class.disabled]="currentImageIndex <= 0"
            (click)="handlePreviousPhoto()">
            <span class="fas fa-chevron-left"></span>
        </div>
        <div class="img-container">
            <img *ngFor="let url of images; let i=index"
                [class.active]="i == currentImageIndex"
                [class.previous]="i <currentImageIndex"
                [class.next]="i> currentImageIndex"
                [src]="url" />
            <div class="infos">
                <ng-content></ng-content>
            </div>
        </div>
        <div class="carousel-button"
            [class.disabled]="currentImageIndex>=
        (images.length - 1)"
            (click)="handleNextPhoto()">
            <span class="fas fa-chevron-right"></span>
        </div>
    </div>

</div>