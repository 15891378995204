import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {MessageService} from './message.service';

@Injectable({
  providedIn: 'root',
})
export class MessageHandlerService {
  constructor(private messageService: MessageService) {
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  public handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      if (error.status === 503) {
        this.log('error', `${operation} - Server not available`, 3, true);
      } else {
        this.log(
          'error',
          `${operation} - ${error.error?.description}`,
          3,
          true
        );
      }

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a message with the MessageService */
  public log(title: string, content: string, level: number, isActive: boolean) {
    this.messageService.sendMessage({title, content, level, isActive});
  }
}
