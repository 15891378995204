import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-page-menu-template",
  templateUrl: "./page-menu-template.component.html",
  styleUrls: ["./page-menu-template.component.scss"],
})
export class PageMenuTemplateComponent implements OnInit {
  @Input()
  public items: any[] = [];

  constructor() {}

  ngOnInit(): void {}
}
