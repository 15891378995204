import {Component, OnInit} from '@angular/core';
import {EasytrackNotification} from '../../../../core/models/notification.model';
import {NotificationsService} from '../../../../core/services/notifications.service';

@Component({
  selector: "app-notification-toast-container",
  templateUrl: "./notification-toast-container.component.html",
  styleUrls: ["./notification-toast-container.component.scss"],
})
export class NotificationToastContainerComponent implements OnInit {
  private notification$ = this.notificationsService.notification$;

  public notifications: EasytrackNotification[] = [];

  constructor(private notificationsService: NotificationsService) {}

  ngOnInit() {
    this.notification$.subscribe((notification) => {
      this.notifications.push(notification);
    });
  }

  public handleCloseNotification(notification: EasytrackNotification) {
    this.notifications.splice(
      this.notifications.findIndex((n) => n.id == notification.id),
      1
    );
  }
}
