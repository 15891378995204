import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: "app-easy-progress-bar",
  templateUrl: "./easy-progress-bar.component.html",
  styleUrls: ["./easy-progress-bar.component.scss"],
})
export class EasyProgressBarComponent implements OnInit {
  @Input()
  public progress: number = 0;

  @Input()
  public label: string = "";

  @Input()
  public error: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  // ngOnChanges(changes: SimpleChanges) {
  //   if (changes.progress) {
  //     // this.progress = changes.progress.currentValue;
  //   }
  // }
}
