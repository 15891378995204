<div #easySelect class="easy-form-select select-container"
    [class.disabled]="control.disabled"
    [class.readonly]="readonly"
    [class.small]="size === 'small'"
    [class.withErrors]="control.touched && control?.errors">
    <label *ngIf="label"
        [id]="selectName + '_label'"
        [for]="selectName" (click)="handleLabelClick($event)">
        <span>{{ label }}</span>
        <span *ngIf="isRequired" class="required-star">&nbsp;*</span>
    </label>

    <div style="position: relative;">
        <!-- ----------------------------------------  BUTTON TOGGLE  ------------------------------------------------- -->
        <button #toggle class="select-toggle"
            [class.open]="isOpen"
            [tabindex]="control.disabled ? -1 : 0"
            [class.bg-white]="background === 'white'"
            [class.displayAbove]="shouldDisplayAbove || alwaysAbove"
            [id]="selectName" (mousedown)="showOptions(!isOpen, $event)"
            (keyup)="handleButtonKeyup($event)"
            (keydown)="handleButtonKeydown($event)" role="button"
            [attr.aria-expanded]="isOpen" aria-haspopup="listbox"
            [attr.aria-labelledby]="selectName + '_label' + ' ' + selectName">
            <!-- ----------------------------------------  SELECTED OPTION ------------------------------------------------- -->
            <div class="selected-option" [class.primary]="selectedOption?.color
                === 'primary'"
                [class.secondary]="selectedOption?.color === 'secondary'"
                [class.success]="selectedOption?.color === 'success'"
                [class.warning]="selectedOption?.color === 'warning'"
                [class.danger]="selectedOption?.color === 'danger'"
                [class.physical]="selectedOption?.color === 'physical'"
                [class.visio]="selectedOption?.color === 'visio'"
                [class.phone]="selectedOption?.color === 'phone'"
                [class.attendee]="selectedOption?.color === 'attendee'">

                <div *ngIf="selectedOption?.icon as icon" class="option-icon
                    mr-2">
                    <span class="icon" [ngClass]="icon"></span>
                </div>
                <div class="option-label line-clamp-1"
                    [class.empty]="!selectedOption"
                    [style.font-weight]="(!selectedOption?.children ||
                    selectedOption?.children?.length === 0) ? '' : 'bold'">{{
                    selectedOption?.label }}</div>
                <div *ngIf="selectedOption?.iconEnd as iconEnd"
                    class="option-icon ml-2">
                    <span class="icon" [ngClass]="iconEnd"></span>
                </div>
            </div>

            <!-- ----------------------------------------  CLEAR ICON ------------------------------------------------- -->
            <div *ngIf="clearable && selectedOption" class="clear-icon"
                (click)="handleClearClick($event)">
                <span class="fas fa-times-circle"></span>
            </div>
            <!-- ----------------------------------------  TOGGLE ICON ------------------------------------------------- -->
            <div class="toggle-icon basis-5 ">
                <span class="fas fa-chevron-down"></span>
            </div>

        </button>

        <!-- ----------------------------------------  OPTIONS LIST  ------------------------------------------------- -->
        <ul *ngIf="isOpen" #optionsList class="select-options-list shadow"
            [class.displayAbove]="shouldDisplayAbove || alwaysAbove"
            [class.is-visible]="canAppear" tabindex="-1" role="listbox"
            [attr.aria-labelledby]="selectName + '_label'">

            <li *ngIf="search" class="search">
                <app-easy-form-input icon="fas fa-search"
                    [control]="searchControl"
                    (valueChange)="handleSearchChange($event)">
                </app-easy-form-input>
            </li>

            <!-- ----------------------------------------  OPTION  ------------------------------------------------- -->
            <ng-container *ngFor="let option of displayedOptions">
                <app-easy-select-recursive-option [selectName]="selectName"
                    [option]="option" [paddingLeft]="0.75"
                    [clickableGroups]="clickableGroups"
                    [selectedOptionId]="selectedOption?.id"
                    (optionClick)="handleOptionClick($event)"
                    (optionKeydown)="handleOptionKeydown($event)"
                    (optionKeyup)="handleOptionKeyup($event)">
                </app-easy-select-recursive-option>
            </ng-container>

        </ul>
    </div>
    <!-- ----------------------------------------  ERRORS  ------------------------------------------------- -->
    <app-easy-form-errors *ngIf="errors && control.touched && control?.errors"
        [errors]="errors" [control]="control">
    </app-easy-form-errors>

</div>