import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {EventMessageType} from '../models/event-message-type.enum';
import {EasytrackNotification, EasytrackNotificationType,} from '../models/notification.model';
import {MessageHandlerService} from './messageHandler.service';
import {SseEventsService} from './sse-events.service';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  private _notifications$: BehaviorSubject<EasytrackNotification[]> =
    new BehaviorSubject<EasytrackNotification[]>([]);

  private _notification$: Subject<EasytrackNotification> =
    new Subject<EasytrackNotification>();

  private serviceUrl: string = environment.baseUrl + "/notifications";

  private audio: HTMLAudioElement = new Audio();

  constructor(
    private http: HttpClient,
    private messageService: MessageHandlerService,
    private translate: TranslateService,
    private sseService: SseEventsService
  ) {
    this.audio.src = "assets/audios/notification.mp3";
    // this.audio.volume = 0.5;

    this.sseService
      .onSubject(EventMessageType.NEW_NOTIFICATION)
      .subscribe((event) => {
        this.addNotification(event.data);
        this.playNotificationAudio();
      });
  }

  public getNotifications(): Observable<EasytrackNotification[]> {
    return this.http.get<EasytrackNotification[]>(this.serviceUrl).pipe(
      tap((notifications) => {
        this._notifications$.next(notifications);
      }),
      catchError(
        this.messageService.handleError<EasytrackNotification[]>(
          this.translate.instant("LOGS.NOTIFICATIONS.GET_ALL_FAILURE"),
          []
        )
      )
    );
  }

  public toggleNotificationSeen(
    id: number
  ): Observable<EasytrackNotification | undefined> {
    return this.http
      .put<EasytrackNotification | undefined>(
        this.serviceUrl + "/" + id + "/read",
        {}
      )
      .pipe(
        tap((notification) => {
          if (notification) {
            const notifs = this._notifications$.getValue();
            const index = notifs.findIndex((n) => n.id == id);
            notifs.splice(index, 1, notification);
            this._notifications$.next(notifs);
          }
        }),
        catchError(
          this.messageService.handleError<EasytrackNotification>(
            this.translate.instant("LOGS.NOTIFICATIONS.TOGGLE_SEEN_FAILURE")
          )
        )
      );
  }

  public addNotification(notification: EasytrackNotification): void {
    const notifs = this._notifications$.getValue();
    notifs.splice(0, 0, notification);
    this._notifications$.next(notifs);
    this._notification$.next(notification);
    this.playNotificationAudio();
  }

  public generateFakeNotification() {
    return this.http.post(this.serviceUrl, {
      notifType: EasytrackNotificationType.DEFAULT,
      text: "Cette notif a été générée à la main !",
      title: "NOTIFICATION GENEREE",
    });
  }

  private playNotificationAudio() {
    if (this.audio.paused) {
      this.audio.play();
      setTimeout(() => {
        this.audio.pause();
        this.audio.currentTime = 0;
      }, 1000);
    }
  }

  //  ----------------------------------------------- ACCESSORS ---------------------------------------------------------------- //

  public get notifications$(): Observable<EasytrackNotification[]> {
    return this._notifications$.asObservable();
  }

  public get notification$(): Observable<EasytrackNotification> {
    return this._notification$.asObservable();
  }
}
