<div class="easy-modal" [class.open]="open" (click)="closeModal(true);
    $event.stopPropagation();">
    <div *ngIf="open" class="easy-modal-container" [class.fitWidth]="fitWidth"
        [style.flex-grow]="fullHeight ? 1 : 0"
        (click)="$event.stopPropagation()">
        <div class="easy-modal-header">
            <span>{{ modalTitle }}</span>
            <app-easy-button icon="fas fa-times fa-lg" buttonStyle="borderless"
                (click)="closeModal()">
            </app-easy-button>
        </div>
        <div class="easy-modal-body">
            <ng-content></ng-content>
        </div>
        <div class="easy-modal-footer">
            <ng-content select="[footer]"></ng-content>
        </div>
    </div>
</div>