import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { interval, Subject } from "rxjs";
import { takeUntil, tap } from "rxjs/operators";
import * as XLSX from "xlsx";
import { EasytrackSortOrder } from "../../../../core/models/easytrack-http-params.model";
import { EventMessageType } from "../../../../core/models/event-message-type.enum";
import { SseEventsService } from "../../../../core/services/sse-events.service";
import { EasySelectOption } from "../forms/easy-form-select/easy-form-select.component";

@Component({
  selector: "app-easy-table",
  templateUrl: "./easy-table.component.html",
  styleUrls: ["./easy-table.component.scss"],
})
export class EasyTableComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild("table") table: ElementRef;

  @Input()
  public options: EasyTableOptions;

  @Input()
  public entries: any[] = [];

  @Input()
  public localPagination: boolean = false;

  @Input()
  public tableId: string = "";

  @Input()
  public filters: EasyTableFilter[] = [];

  @Output()
  public saveUserSettings: EventEmitter<any> = new EventEmitter();

  private onDestroy$: Subject<void> = new Subject();
  public pageCount: number = 1;
  public filteredEntries: any[] = [];
  public filteredSearchedEntries: any[] = [];
  public displayedEntries: any[] = [];
  public loadingData: boolean = true;
  public searchInputControl: FormControl = new FormControl("");
  public pageSizeControl: FormControl = new FormControl(10);
  public pageSizeOptions: EasySelectOption[] = [];
  private draw: number = 1;
  private timer: any;

  public displayedFilters: any[] = [];

  public loadingExport: boolean = false;
  public exportProgress: number = 0;
  public exportError: boolean = false;
  public exportData: boolean = false;
  public exportDataProgress: number = 0;

  public loadingUserSettings: boolean = false;
  public settingsModalOpen: boolean = false;
  public modalTitle: string = "";
  public modalOpen: boolean = false;
  public printControl: FormControl = new FormControl(false);
  public printOptions: EasySelectOption[] = [
    {
      id: "false",
      label: this.translate.instant("WORKFLOW.GLOBAL.EXPORT_PAGE_ONLY"),
      value: false,
    },
    {
      id: "true",
      label: this.translate.instant("WORKFLOW.GLOBAL.EXPORT_ALL_DATA"),
      value: true,
    },
  ];

  private exportType: "csv" | "pdf" | "xlsx" = "csv";

  public allEntries: any[] = [];
  public exporting: boolean = false;

  public columnSettings: any[] = [];

  constructor(
    private translate: TranslateService,
    private sseEvents: SseEventsService
  ) {}

  ngOnInit(): void {
    this.sseEvents
      .onSubject(EventMessageType.DATA_STREAM)
      .pipe(
        takeUntil(this.onDestroy$),
        tap((event) => {
          if (event && event.dataType == this.config.dataType) {
            event.data.forEach((item: any) => {
              this.allEntries.push(item);
            });
          }
        })
      )
      .subscribe();

    this.sseEvents
      .onSubject(EventMessageType.DATA_STREAM_NOTICE)
      .pipe(
        takeUntil(this.onDestroy$),
        tap((event) => {
          if (event && event.dataType == this.config.dataType) {
            switch (event.data.type) {
              case "END_OF_STREAM": {
                this.allEntries = [...new Set(this.allEntries)];
                this.allEntries.sort((a, b) => {
                  if (a.serial_number > b.serial_number) {
                    return 1;
                  } else if (a.serial_number < b.serial_number) {
                    return -1;
                  } else {
                    return 0;
                  }
                });
                this.exportData = true;
                setTimeout(() => {
                  if (this.exportType == "pdf") {
                    this.exportPDF(true);
                  } else if (this.exportType == "csv") {
                    this.exportCSV(true);
                  } else if (this.exportType == "xlsx") {
                    this.exportXLSX(true);
                  }
                  setTimeout(() => {
                    this.modalOpen = false;
                    this.modalTitle = "";
                    this.printControl.setValue(false);
                    this.loadingExport = false;
                    this.exportProgress = 0;
                    this.exportDataProgress = 0;
                    this.exportData = false;
                    this.printControl.setValue(false);
                  }, 2000);
                }, 1000);
              }
            }
          }
        })
      )
      .subscribe();

    this.sseEvents
      .onSubject(EventMessageType.DATA_STREAM_PROGRESS)
      .pipe(
        takeUntil(this.onDestroy$),
        tap((event) => {
          if (event && event.dataType == this.config.dataType) {
            this.exportProgress = event.data.value;
          }
        })
      )
      .subscribe();

    this.initPageSizeOptions();
    this.initTable();
    if (!this.localPagination) {
      this.loadData();
    }
    this.initFilters();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.entries) {
      if (this.localPagination) {
        this.initTable();
        this.loadingData = false;
      } else {
        this.displayedEntries = changes.entries.currentValue || [];
        this.setPageCount();
      }
    }

    if (
      changes.entries?.firstChange == false ||
      // !changes.entries?.currentValue ||
      changes.entries?.currentValue.length > 0
    ) {
      this.loadingData = false;
    }

    if (changes.filters) {
      this.initFilters();
    }

    if (changes.options) {
      this.initPageSizeOptions();
      this.initTable();
      if (!this.localPagination) {
        this.loadData();
      }
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public static reloadData(config: EasyTableConfig) {
    const param: EasyTableParameters = {
      // draw: 0,
      // length: config.pageSize,
      limit: config.pageSize,
      page: 0,
      sortBy: config.sortBy,
      sortOrder: config.sortOrder,
      search: "",
    };

    config.loadDataCallback(param);
  }

  // ------------------------------------------------------- INIT ------------------------------------------------------------ //

  private initTable() {
    this.pageSizeControl.setValue(this.config.pageSize);
    this.filteredEntries = this.entries.slice();
    this.filteredSearchedEntries = this.entries.slice();

    if (this.localPagination) {
      this.searchInputControl.setValue("");
      this.sortEntries();
    }

    if (this.config.totalCount > 0 && this.config.pageSize > 0) {
      this.setPageCount();
      if (this.localPagination) {
        this.displayedEntries = this.filteredSearchedEntries.slice(
          this.config.pageSize * (this.config.page - 1),
          this.getSliceEnd()
        );
      } else {
        this.displayedEntries = this.filteredEntries;
      }
    } else {
      this.displayedEntries = this.filteredEntries;
    }
  }

  private initPageSizeOptions() {
    this.pageSizeOptions = this.config.pageSizeOptions.map((opt) => {
      return {
        id: opt.toString(),
        label: opt,
        value: opt,
      };
    });
  }

  private initFilters() {
    this.displayedFilters = this.filters.map((f) => {
      const displayed = {
        field: f.field || "",
        label: f.label || "",
        control: new FormControl(undefined),
        options: f.options.map((o) => {
          const opt: EasySelectOption = {
            id: o.value,
            label: o.label,
            value: o.value,
          };
          return opt;
        }),
        sorted: f.sorted != undefined ? f.sorted : true,
        search: f.search,
      };
      return displayed;
    });
  }

  // --------------------------------------------------- EVENT HANDLERS ------------------------------------------------------ //
  public handlePageClick(page: string): void {
    const pageNumber = Number(page);

    if (pageNumber != this.config.page) {
      if (pageNumber >= 1 && pageNumber <= this.pageCount) {
        this.config.page = pageNumber;

        if (!this.localPagination) {
          this.loadData();
        }
      }

      if (this.localPagination) {
        this.displayedEntries = this.filteredSearchedEntries.slice(
          this.config.pageSize * (this.config.page - 1),
          this.getSliceEnd()
        );
      }
    }
  }

  public handlePageSizeChange(): void {
    this.config.page = 1;

    this.config.pageSize = this.pageSizeControl.value;

    this.setPageCount();

    if (this.localPagination) {
      this.sortEntries();
    }

    if (!this.localPagination) {
      this.loadData();
    }
  }

  public handleHeaderClick(column: EasyTableColumn, index: number): void {
    if (
      this.config.sortable &&
      !column.disableSort &&
      (column.type == "text" || column.type == "icon")
    ) {
      const config = this.options.config;
      config.page = 1;
      if (
        config.sortBy == column.alias ||
        config.sortBy == column.attributeName
      ) {
        const newOrder =
          config.sortOrder == EasytrackSortOrder.DESC
            ? EasytrackSortOrder.ASC
            : EasytrackSortOrder.DESC;
        config.sortOrder = newOrder;
      } else {
        config.sortByIndex = index;
        config.sortBy = column.alias ?? column.attributeName;
        config.sortOrder = EasytrackSortOrder.ASC;
      }
      if (this.localPagination) {
        this.sortEntries();
      } else {
        this.loadData();
      }
    }
  }

  public handleCellClick(column: EasyTableColumn, entry: any): void {
    if (column.type == "checkbox") {
      entry[column.attributeName] =
        entry[column.attributeName] === true ? false : true;
    }

    if (column.callback) {
      column.callback(entry);
    }
  }

  public handleSearchKeyUp(value: string): void {
    if (this.localPagination) {
      this.config.page = 1;
      this.filteredSearchedEntries = this.getFilteredEntries();
      this.sortEntries();
    } else {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.config.page = 1;
        this.loadData();
      }, 300);
    }
  }

  public handleFilterChange() {
    this.config.page = 1;
    if (this.localPagination) {
      this.filteredEntries = this.entries;

      this.displayedFilters.forEach((f) => {
        const value = f.control.value;
        const fields = f.field.split(".");
        if (value != undefined && value.toString() != "") {
          this.filteredEntries = this.filteredEntries.filter((e) => {
            let elementValue = e;
            fields.forEach((f) => {
              elementValue = elementValue[f];
            });
            return elementValue == value;
          });
        }
      });

      this.filteredSearchedEntries = this.getFilteredEntries();
      this.sortEntries();
    } else {
      this.loadData();
    }
  }

  public handleSettingsClick() {
    this.settingsModalOpen = true;
    this.columnSettings = this.config.userSettings.columns.map((c) => {
      return {
        ...c,
        control: new FormControl(c.control.value),
      };
    });
  }

  public handleSaveUserSettings() {
    const toEmit: any = {};

    this.columnSettings.map((col) => {
      toEmit[col.alias ?? col.name] = col.control.value;
    });

    this.saveUserSettings.emit(toEmit);
    this.settingsModalOpen = false;
  }

  // --------------------------------------------------- EVENT HANDLERS -> CHECKBOX ------------------------------------------------------ //

  public handleCheckboxKeyUp(
    event: KeyboardEvent,
    column: EasyTableColumn,
    entry: any
  ): void {
    const key = event.key;
    switch (key) {
      case " ": {
        this.handleCellClick(column, entry);
        event.preventDefault();
        break;
      }
      case "Enter": {
        this.handleCellClick(column, entry);
        break;
      }
      default: {
        break;
      }
    }
  }

  public handleCheckboxKeyDown(event: KeyboardEvent): void {
    const key = event.key;
    switch (key) {
      case " ": {
        event.preventDefault();
        break;
      }
      default: {
        break;
      }
    }
  }

  // ------------------------------------------------------- SORTING ------------------------------------------------------------ //

  private sortEntries(): void {
    const config = this.options.config;
    this.filteredSearchedEntries.sort((a, b) => {
      if (config.sortBy) {
        if (
          this.getCellValue(a, config.sortBy) == undefined ||
          this.getCellValue(a, config.sortBy) == null
        ) {
          return 1;
        }
        if (
          this.getCellValue(b, config.sortBy) == undefined ||
          this.getCellValue(b, config.sortBy) == null
        ) {
          return -1;
        }

        if (
          this.getCellValue(a, config.sortBy).toString().toLowerCase() >
          this.getCellValue(b, config.sortBy).toString().toLowerCase()
        ) {
          return config.sortOrder == EasytrackSortOrder.ASC ? 1 : -1;
        } else {
          return config.sortOrder == EasytrackSortOrder.ASC ? -1 : 1;
        }
      } else {
        return 1;
      }
    });

    this.displayedEntries = this.filteredSearchedEntries.slice(
      this.config.pageSize * (this.config.page - 1),
      this.getSliceEnd()
    );
  }

  private getFilteredEntries(): any[] {
    const toFilter = this.filteredEntries;
    let newFilteredEntries = toFilter;

    if (this.searchInputControl.value.trim() != "") {
      newFilteredEntries = toFilter.filter((entry) => {
        const isMatching = this.config.searchAttributes.some(
          (searchAttribute) => {
            const splittedName = searchAttribute.split(".");
            let value = entry;

            splittedName.forEach((attribute) => {
              value = value[attribute];
            });

            if (
              value
                ?.toString()
                .toLowerCase()
                .includes(this.searchInputControl.value.toLowerCase())
            ) {
              return true;
            }
            return false;
          }
        );

        return isMatching;
      });
    }

    this.config.totalCount = newFilteredEntries.length;

    if (this.config.pageSize > 0) {
      this.setPageCount();
      // this.displayedEntries = this.entries.slice(
      //   this.config.pageSize * (this.config.page - 1),
      //   this.getSliceEnd()
      // );
    }

    return newFilteredEntries;
  }

  // ------------------------------------------------------- PAGINATION ------------------------------------------------------------ //

  public getSliceEnd(): number | undefined {
    if (this.config.pageSize > 0) {
      return this.config.pageSize * this.config.page;
    } else {
      return undefined;
    }
  }

  private setPageCount(): void {
    this.pageCount = Math.ceil(this.config.totalCount / this.config.pageSize);
  }

  // ------------------------------------------------------- MISC ------------------------------------------------------------ //

  public getCellValue(entry: any, attributeName: string) {
    const splittedName = attributeName.split(".");

    let value = entry;
    splittedName.forEach((attribute) => {
      if (value) {
        value = value[attribute];
      }
    });

    return value;
  }

  private getDTParameters(): EasyTableParameters {
    const param: EasyTableParameters = {
      page: this.config.page - 1,
      // draw: this.draw,
      // length: this.config.pageSize,
      sortBy: this.config.sortBy,
      sortOrder: this.config.sortOrder,
      search: this.searchInputControl.value,
      limit: this.config.pageSize,
    };

    this.displayedFilters.forEach((f) => {
      if (f.control.value != undefined) {
        param[f.field] = f.control.value;
      }
    });

    return param;
  }

  private loadData(): void {
    this.loadingData = true;
    this.config.loadDataCallback(this.getDTParameters());
    this.draw += 1;
  }

  public spreadClasses(classes: string[]) {
    let string = "";
    classes.forEach((clazz) => (string += clazz + " "));
    return string;
  }

  public getCount() {
    return Math.min(
      this.config.page * this.config.pageSize,
      this.config.totalCount
    );
  }

  public canDisplayColumn(name: string): boolean {
    const foundSetting = this.config.userSettings?.columns.find(
      (c) => c.name == name
    );
    if (foundSetting) {
      return foundSetting.control.value;
    }
    return true;
  }

  // ------------------------------------------------------- EXPORT FUNCTIONS ------------------------------------------------------------ //

  public handleExportPDF(): void {
    this.exporting = true;

    setTimeout(() => {
      // if (this.config.loadAllObs) {
      //   this.exportType = "pdf";
      //   this.modalTitle = "COMMON.COMMON.PDF";
      //   this.modalOpen = true;
      // } else {
      this.exportPDF();
    }, 0);
    // }
  }

  public handleExportCSV(): void {
    if (this.config.loadAllObs) {
      this.exportType = "csv";
      this.modalTitle = "COMMON.COMMON.CSV";
      this.modalOpen = true;
    } else {
      this.exporting = true;

      setTimeout(() => {
        this.exportCSV();
      }, 0);
    }
  }

  public handleExportXLSX(): void {
    setTimeout(() => {
      if (this.config.loadAllObs) {
        this.exportType = "xlsx";
        this.modalTitle = "COMMON.COMMON.EXCEL";
        this.modalOpen = true;
      } else {
        this.exporting = true;

        setTimeout(() => {
          this.exportXLSX();
        }, 0);
      }
    }, 0);
  }

  public handleExport(): void {
    const all: boolean = this.printControl.value;

    if (all) {
      if (this.localPagination) {
        this.allEntries = this.entries.slice();
        this.exportData = true;
        setTimeout(() => {
          if (this.exportType == "pdf") {
            this.exportPDF(true);
          } else if (this.exportType == "csv") {
            this.exportCSV(true);
          } else if (this.exportType == "xlsx") {
            this.exportXLSX(true);
          }
          setTimeout(() => {
            this.modalOpen = false;
            this.modalTitle = "";
            this.printControl.setValue(false);
            this.loadingExport = false;
            this.exportProgress = 0;
            this.exportDataProgress = 0;
            this.exportData = false;
            this.printControl.setValue(false);
          }, 2000);
        }, 1000);
      } else {
        if (this.allEntries.length == 0) {
          this.allEntries = [];
          this.loadingExport = true;
          this.config.loadAllObs().subscribe();
        } else {
          this.exportData = true;

          setTimeout(() => {
            if (this.exportType == "pdf") {
              this.exportPDF(true);
            } else if (this.exportType == "csv") {
              this.exportCSV(true);
            } else if (this.exportType == "xlsx") {
              this.exportXLSX(true);
            }
            setTimeout(() => {
              this.modalOpen = false;
              this.modalTitle = "";
              this.printControl.setValue(false);
              this.loadingExport = false;
              this.exportProgress = 0;
              this.exportDataProgress = 0;
              this.exportData = false;
              this.printControl.setValue(false);
            }, 2000);
          }, 1000);
        }
      }
    } else {
      this.exporting = true;

      setTimeout(() => {
        if (this.exportType == "pdf") {
          this.exportPDF();
        } else if (this.exportType == "csv") {
          this.exportCSV();
        } else if (this.exportType == "xlsx") {
          this.exportXLSX();
        }
      }, 0);
    }
  }

  private exportPDF(all: boolean = false): void {
    let doc = new jsPDF("l", "mm", "a4");

    const displayedHeaders: string[] = this.options.columns.map((col) => {
      return col.label;
    });

    this.options.columns.forEach((col) => {
      if (col.exportLabel) {
        col.label = col.exportLabel;
      }
    });

    setTimeout(() => {
      let html = this.table.nativeElement;

      autoTable(doc, {
        html: html,
      });
      doc.save(this.config.exportFileName);

      this.options.columns.forEach((col, i) => {
        col.label = displayedHeaders[i];
      });
      this.exporting = false;
    }, 0);
  }

  private exportCSV(all: boolean = false): void {
    let csv = "";

    if (all) {
      let index = 0;
      const timer = interval(500).subscribe(() => {
        if (this.allEntries.length != 0) {
          this.exportDataProgress = Math.floor(
            (index / this.allEntries.length) * 100
          );
        } else {
          this.exportDataProgress = 100;
        }
      });

      this.options.columns.forEach((col) => {
        let header = col.label ? this.translate.instant(col.label) : "";
        if (col.exportLabel) {
          header = this.translate.instant(col.exportLabel);
        }
        csv += '"' + header + '",';
      });

      csv = csv.substring(0, csv.length - 1) + "\n";

      this.allEntries.forEach((entry, i) => {
        this.options.columns.forEach((col) => {
          const value = this.getCellValue(entry, col.attributeName);
          csv += '"' + value + '"' + ",";
        });
        csv = csv.substring(0, csv.length - 1) + "\n";
        index = i;
      });

      csv = csv.substring(0, csv.length - 1) + "\n";
      setTimeout(() => {
        timer.unsubscribe();
      }, 0);
    } else {
      const table = this.table.nativeElement;

      let tr = table.children[0].children[0];
      for (let i = 0; i < tr.children.length; i++) {
        let header = this.options.columns[i].label
          ? this.translate.instant(this.options.columns[i].label)
          : "";
        if (this.options.columns[i].exportLabel) {
          header = this.translate.instant(this.options.columns[i].exportLabel);
        }

        csv += '"' + header + '"' + ",";
      }
      csv = csv.substring(0, csv.length - 1) + "\n";
      let tbody = table.children[1];
      for (let i = 0; i < tbody.children.length; i++) {
        for (let j = 0; j < tbody.children[i].children.length; j++) {
          csv += '"' + tbody.children[i].children[j].innerText + '"' + ",";
        }
        csv = csv.substring(0, csv.length - 1) + "\n";
      }
      csv = csv.substring(0, csv.length - 1) + "\n";
    }

    let hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
    hiddenElement.target = "_blank";
    hiddenElement.download = this.config.exportFileName + ".csv";
    hiddenElement.click();
    this.exportDataProgress = 100;
    this.exporting = false;
  }

  private exportXLSX(all: boolean = false): void {
    let table: any;
    let ws: XLSX.WorkSheet;

    if (all) {
      let index = 0;
      const timer = interval(500).subscribe(() => {
        if (this.allEntries.length != 0) {
          this.exportDataProgress = Math.floor(
            (index / this.allEntries.length) * 100
          );
        } else {
          this.exportDataProgress = 100;
        }
      });

      const toSheet = this.allEntries.map((e, i) => {
        const mapped: any = {};
        this.options.columns.forEach((c) => {
          mapped[c.attributeName] = this.getCellValue(e, c.attributeName);
        });

        index = i;
        return mapped;
      });

      ws = XLSX.utils.json_to_sheet(toSheet);
      const headers = [];
      this.options.columns.forEach((col) => {
        let header = col.label ? this.translate.instant(col.label) : "";
        if (col.exportLabel) {
          header = this.translate.instant(col.exportLabel);
        }
        headers.push(header);
      });
      XLSX.utils.sheet_add_aoa(ws, [headers], { origin: "A1" });
      setTimeout(() => {
        timer.unsubscribe();
      }, 0);

      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

      /* save to file */
      XLSX.writeFile(wb, this.config.exportFileName + ".xlsx");

      this.exportDataProgress = 100;
      this.exporting = false;
    } else {
      const displayedHeaders: string[] = this.options.columns.map((col) => {
        return col.label;
      });

      this.options.columns.forEach((col) => {
        if (col.exportLabel) {
          col.label = col.exportLabel;
        }
      });

      setTimeout(() => {
        table = this.table.nativeElement;

        ws = XLSX.utils.table_to_sheet(table);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        /* save to file */
        XLSX.writeFile(wb, this.config.exportFileName + ".xlsx");

        this.exportDataProgress = 100;
        this.exporting = false;

        this.options.columns.forEach((col, i) => {
          col.label = displayedHeaders[i];
        });
      }, 0);
    }
  }

  // ------------------------------------------------------- ACCESSORS ------------------------------------------------------------ //

  public get config() {
    return this.options.config;
  }
}

export interface EasyTableColumn {
  label: string;
  exportLabel?: string;
  attributeName: string;
  alias?: string;
  maxWidth?: string;
  minWidth?: string;
  width?: string;
  type: "text" | "icon" | "checkbox";
  ellipsis?: boolean;
  color?: (data) => string;
  icon?: (data) => string;
  title?: string;
  disableSort?: boolean;
  callback?: Function;
  classCallback?: (data) => string[];
}

export interface EasyTableOptions {
  columns: EasyTableColumn[];
  config: EasyTableConfig;
  ariaLabel: string;
}

export class EasyTableConfig {
  sortByIndex: number = 0;
  sortBy: string = "";
  sortOrder: EasytrackSortOrder = EasytrackSortOrder.ASC;
  sortable: boolean = true;
  pageSizeControl: boolean = true;
  pageSizeOptions: number[] = [10, 20, 50, 100];
  pageSize: number = 10;
  page: number = 1;
  totalCount: number = 0;
  noDataMessage: string = "";
  noDataSearchMessage: string = "";
  withSearch: boolean = false;
  buttons: string[] = [];
  searchAttributes: string[] = [];
  searchPlaceholder: string = "";
  exportFileName: string = "table";
  loadDataCallback?: (tableParameters: EasyTableParameters) => void = (
    tableParameters
  ) => {};
  rowClassCallback?: (data: any) => string[];
  rowCallback?: (data: any) => void;
  loadAllObs?: any;
  dataType: string = "ALL_TIRES";
  userSettings: EasyTableUserSettings;
}

export enum EasyTableSortOrder {
  ASC = "asc",
  DESC = "desc",
}

export interface EasyTableParameters {
  limit: number;
  page: number;
  sortBy: string;
  sortOrder: { column: string; dir: EasyTableSortOrder }[] | EasytrackSortOrder;
  search: { value: string; regex: boolean } | string;
}

export interface EasyTableFilter {
  field: string;
  label: string;
  options: { label: string; value: any }[];
  sorted?: boolean;
  search?: boolean;
}

export interface EasyTableUserSettings {
  columns: {
    label: string;
    name: string;
    alias?: string;
    control: FormControl;
  }[];
}
