import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: "app-card",
  templateUrl: "./card.component.html",
  styleUrls: ["./card.component.scss"],
})
export class CardComponent implements OnInit {
  @Input()
  public autoWidth: boolean = false;

  @Input()
  public url: string[] = [];

  constructor() {}

  ngOnInit(): void {}
}
