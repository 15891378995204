import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Message} from '../models/message.model';

@Injectable({
  providedIn: "root",
})
export class MessageService {
  private subject = new Subject<any>();

  sendMessage(message: Message) {
    this.subject.next({
      title: message.title,
      content: message.content,
      level: message.level,
      isActive: message.isActive,
    });
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  clearMessage() {
    this.subject.next({});
  }
}
