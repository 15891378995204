<div class="page-menu-template">

    <aside>
        <ng-content></ng-content>
    </aside>

    <div class="content">
        <router-outlet></router-outlet>
    </div>

</div>