import {Component, Input} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: "app-page-header-back-button",
  templateUrl: "./page-header-back-button.component.html",
  styleUrls: ["./page-header-back-button.component.scss"],
})
export class PageHeaderBackButtonComponent {
  @Input() public backUrl: string = "";
  @Input() public params: any = {};

  constructor(private router: Router, private route: ActivatedRoute) {}

  public handleClose(): void {
    let url = this.backUrl;
    if (!url) {
      this.router.navigate([".."], {
        relativeTo: this.route,
        queryParams: this.params,
      });
    } else {
      this.router.navigate([url], {
        queryParams: this.params,
      });
    }
  }
}
