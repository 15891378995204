import {Component, Input} from '@angular/core';

@Component({
  selector: "app-led-indicator",
  templateUrl: "./led-indicator.component.html",
  styleUrls: ["./led-indicator.component.scss"],
})
export class LedIndicatorComponent {
  @Input()
  public enabled: boolean = false;

  @Input()
  public enabledText: string = "";

  @Input()
  public disabledText: string = "";
}
